import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { string } from "yup";
import { PublicURLImages } from "../config";

import Modal from "@mui/material/Modal";
import service from "../service/Service";

export function ViewProjectDetails() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const role = localStorage.getItem("role");
  const [comment, setComment] = useState("");
  const [valid, setValid] = useState(false);
  const [valid1, setValid1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seat_no, setSeat_no] = useState("");
  const handlechange = (e) => {
    const value = e.currentTarget.value;
    setComment(value);
    setValid(string().required().isValidSync(value));
  };
  const handlechange1 = (e) => {
    const value = e.currentTarget.value;
    setSeat_no(value);
    setValid1(string().required().isValidSync(value));
  };

  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState(undefined);

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    setLoading(true);
    service
      .get(`project/${id}`)
      .then((res) => {
        setLoading(false);
        //console.log(res.data.projects[0], "Hello Data");
        setProjectDetails(res.data.projects[0]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    width: 200,
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  const DownlaodFile = () => {
    console.log("download");
  };

  // const AdminForm = useFormik();

  const ApproveBtn = ({ id }) => {
    return (
      <Button
        variant="contained"
        sx={{ marginTop: "12px" }}
        disabled={!valid}
        onClick={() => {
          service
            .post(`approve-project/${id}`, {
              comments: comment,
            })
            .then((res) => {
              //console.log(res);
              getProjectDetails();
            });
        }}
      >
        Approve
      </Button>
    );
  };

  const CenterReject = ({ id }) => {
    return (
      <Button
        color="error"
        variant="outlined"
        disabled={!valid}
        onClick={() => {
          service
            .post(`center-revise-project/${id}`, {
              comments: comment,
            })
            .then((res) => {
              //console.log(res);
              getProjectDetails();
            });
        }}
      >
        revise and resubmit
      </Button>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const PublishBtn = ({ id, seat }) => {
    return (
      <Button
        variant="contained"
        sx={{ marginTop: "12px" }}
        disabled={!valid}
        onClick={() => {
          handlepub(id, seat);
        }}
      >
        Publish
      </Button>
    );
  };

  const handlepub = (id, seat) => {
    service
      .post(`publish-project/${id}`, {
        comments: comment,
        seat_no: seat,
      })
      .then((res) => {
        //console.log(res);
        setComment("");
        setSeat_no("");
        getProjectDetails();
      });
  };

  const FsoReject = ({ id }) => {
    return (
      <Button
        color="error"
        variant="outlined"
        disabled={!valid}
        onClick={() => {
          service
            .post(`fso-revise-project/${id}`, {
              comments: comment,
            })
            .then((res) => {
              //console.log(res);
              getProjectDetails();
            });
        }}
      >
        revise and resubmit
      </Button>
    );
  };

  return (
    <>
      {!loading ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {projectDetails ? (
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.default",
                  p: 3,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Toolbar />
                <div className="grandContainer">
                  <div className="FormContainer">
                    <div className="innerContainer">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          flexWrap: "wrap",
                        }}
                      >
                        <img
                          className="logoImage"
                          style={{ marginRight: "10px" }}
                          id="logoimage"
                          src={`${PublicURLImages}projects/${projectDetails.logo}`}
                          alt="preview image"
                        />
                        <div>
                          <p
                            className="subTitle"
                            style={{
                              fontSize: 36,
                              margin: 0,
                              textAlign: "start",
                            }}
                          >
                            {projectDetails.p_name}
                          </p>
                          <p
                            className="subTitle"
                            style={{
                              fontSize: 18,
                              margin: 0,
                              textAlign: "start",
                            }}
                          >
                            {projectDetails.center_name}
                          </p>
                          <p
                            className="subTitle"
                            style={{
                              fontSize: 14,
                              margin: 0,
                              textAlign: "start",
                            }}
                          >
                            {projectDetails.s_desc}
                          </p>
                          <div style={{ textAlign: "start" }}>
                            {projectDetails.release_type === "apk" ? (
                              <>
                                <ColorButton
                                  onClick={() => DownlaodFile()}
                                  startIcon={<DownloadIcon />}
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: "#169b8a",
                                    marginTop: "15px",
                                    textAlign: "start",
                                  }}
                                >
                                  Download
                                </ColorButton>
                              </>
                            ) : (
                              <>
                                <a
                                  href={projectDetails.file_path}
                                  target="_blank"
                                >
                                  <ColorButton
                                    onClick={() => DownlaodFile()}
                                    variant="outlined"
                                    sx={{
                                      backgroundColor: "#169b8a",
                                      marginTop: "15px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Go To Web
                                  </ColorButton>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="subTitle">Project Images</p>
                      <ImageList
                        sx={{ width: "auto", height: "auto", margin: "0" }}
                        cols={3}
                        rowHeight={200}
                      >
                        {projectDetails.images.map((item, i) => (
                          <ImageListItem key={i} className="imageContainer">
                            <img
                              src={`${PublicURLImages}projects/${item[0]}`}
                              // srcSet={`${item.img}`}
                              width="100px"
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                      <p className="subTitle">Project Video</p>
                      <div style={{ marginTop: 10 }}>
                        <iframe
                          width="100%"
                          height="500"
                          style={{ borderRadius: 10 }}
                          src={`https://www.youtube.com/embed/${
                            projectDetails.video.split("v=")[1]
                          }`}
                        ></iframe>
                      </div>

                      <p className="subTitle" style={{ fontSize: 30 }}>
                        Description
                      </p>

                      <p
                        className="subTitle"
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          fontWeight: "lighter",
                          lineBreak: "anywhere",
                        }}
                      >
                        {projectDetails.l_desc}
                      </p>

                      <p className="subTitle" style={{ fontSize: 30 }}>
                        Publisher Details
                      </p>
                      <div style={{}}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "green",
                              fontSize: 18,
                              marginTop: "10px",
                              textAlign: "start",
                            }}
                          >
                            <span style={{ color: "#000", fontWeight: "bold" }}>
                              Student Name :{" "}
                            </span>
                            {projectDetails.created_by}
                          </span>
                          <span
                            style={{
                              color: "green",
                              fontSize: 18,
                              marginTop: "10px",
                              textAlign: "start",
                            }}
                          >
                            <span style={{ color: "#000", fontWeight: "bold" }}>
                              Student ID :{" "}
                            </span>
                            Std{projectDetails.created_by_id}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "green",
                              fontSize: 18,
                              marginTop: "10px",
                              textAlign: "start",
                            }}
                          >
                            <span style={{ color: "#000", fontWeight: "bold" }}>
                              Team Name :{" "}
                            </span>
                            {projectDetails.team_name}
                          </span>

                          {projectDetails.team.length !== 0 ? (
                            <>
                              <span
                                style={{
                                  color: "green",
                                  fontSize: 18,
                                  marginTop: "10px",
                                  textAlign: "start",
                                }}
                              >
                                <span
                                  style={{ color: "#000", fontWeight: "bold" }}
                                >
                                  Team Members :{" "}
                                </span>
                              </span>
                              {projectDetails.team.map((e, i) => (
                                <span
                                  key={i}
                                  style={{
                                    color: "green",
                                    fontSize: 18,
                                    marginTop: "10px",
                                    textAlign: "start",
                                  }}
                                >
                                  {e.std_name} - Std{e.std_id}
                                </span>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "green",
                              fontSize: 18,
                              marginTop: "10px",
                              textAlign: "start",
                            }}
                          >
                            <span style={{ color: "#000", fontWeight: "bold" }}>
                              Mentor Name :{" "}
                            </span>{" "}
                            {projectDetails.teacher}
                          </span>
                        </div>
                        {/* <p>Team Members Names</p> */}
                      </div>
                    </div>
                    {role === "xxca" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "50px",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {projectDetails.status === 1 ? (
                            <></>
                          ) : (
                            <>
                              {projectDetails.status === 2 ? (
                                <></>
                              ) : (
                                <>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Comments"
                                    multiline
                                    rows={4}
                                    style={{ width: "50%" }}
                                    onChange={handlechange}
                                    error={!valid}
                                  />
                                </>
                              )}
                            </>
                          )}
                          <div
                            style={{ textAlign: "start", marginLeft: "12px" }}
                          >
                            {projectDetails.status === 0 ? (
                              <CenterReject id={projectDetails.p_id} />
                            ) : (
                              <>
                                {projectDetails.status === 3 ? (
                                  <CenterReject id={projectDetails.p_id} />
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                            <br />
                            {projectDetails.status === 0 ? (
                              <ApproveBtn id={projectDetails.p_id} />
                            ) : (
                              <>
                                {projectDetails.status === 3 ? (
                                  <ApproveBtn id={projectDetails.p_id} />
                                ) : (
                                  <>
                                    {projectDetails.status === 4 ? (
                                      <ApproveBtn id={projectDetails.p_id} />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {role === "xxsa" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginTop: "50px",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {projectDetails.status === 1 ? (
                                <>
                                  <div style={{ width: "50%" }}>
                                    {/* <TextField
                                      id="outlined-multiline-static1"
                                      label="Seat No"
                                      onChange={handlechange1}
                                      value={seat_no}
                                      error={!valid1}
                                      style={{ width: "100%" }}
                                    /> */}
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="Comments"
                                      multiline
                                      rows={4}
                                      onChange={handlechange}
                                      value={comment}
                                      error={!valid}
                                      style={{
                                        width: "100%",
                                        marginTop: "12px",
                                      }}
                                    />

                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style}>
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          sx={{ textAlign: "center" }}
                                        >
                                          Seat No.
                                        </Typography>
                                        <TextField
                                          id="outlined-multiline-static1"
                                          label="Seat No"
                                          onChange={handlechange1}
                                          value={seat_no}
                                          error={!valid1}
                                          style={{ width: "100%" }}
                                        />

                                        <Button
                                          sx={{ width: "100%", marginTop: 1 }}
                                          disabled={!valid1}
                                          variant="contained"
                                          onClick={() =>
                                            handlepub(projectDetails.p_id)
                                          }
                                        >
                                          Publish
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  textAlign: "start",
                                  marginLeft: "12px",
                                }}
                              >
                                {projectDetails.status === 1 ? (
                                  <FsoReject id={projectDetails.p_id} />
                                ) : (
                                  <></>
                                )}
                                <br />
                                {projectDetails.status === 1 ? (
                                  <PublishBtn
                                    id={projectDetails.p_id}
                                    seat={projectDetails.seat_no}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <TableContainer
                      component={Paper}
                      style={{ marginTop: "20px" }}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Sno.</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Comment By</TableCell>
                            <TableCell>Comment</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projectDetails.project_history.map((e, i) => (
                            <TableRow key={i}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{e.status}</TableCell>
                              <TableCell>{e.comments_by}</TableCell>
                              <TableCell>{e.comment}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Box>
            ) : (
              <>
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ color: "#aaa" }}>No Data Found...</h2>
                </div>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <div
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
}
