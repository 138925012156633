import { Autocomplete, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
import { useDispatch, useSelector } from "react-redux";
import service from "../../service/Service";

const validationSchema = yup.object({
  teacher: yup
    .string("Select your Teacher Name")
    .required("Teacher Name Required"),
  teacherid: yup
    .string("Select your Teacher Name")
    .required("Teacher Name is Required"),
});

export const FacultyDetails = () => {
  const dispatch = useDispatch();
  const [getfaculty, Setfaculty] = useState([]);
  const [teacherid, setteacherid] = useState("");
  const myAllData = useSelector((state) => state.Alldata.data);

  const formik = useFormik({
    initialValues: {
      teacher: myAllData.teacher || "",
      teacherid:
        myAllData.teacherid || myAllData.teacher_id || myAllData.t_id || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({ type: "next", payload: true });
      dispatch({
        type: "addxyz",
        payload: values,
      });
    },
  });

  const GetAllFaculty = () => {
    let role = localStorage.getItem("role");

    if (role === "xxst") {
      service.get(`faculty`).then((res) => {
        let { teachers } = res.data;
        Setfaculty(teachers);
      });
    } else if (role === "xxca") {
      service.get(`center-teachers`).then((res) => {
        let { teachers } = res.data;
        console.log(res.data);
        Setfaculty(teachers);
      });
    }
  };
  React.useEffect(() => {
    GetAllFaculty();
  }, []);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Faculty Details</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Teacher Name</p>
                <Autocomplete
                  fullWidth
                  placeholder="Select Teacher"
                  options={getfaculty.map((e) => e)}
                  getOptionLabel={(option) => option.t_name}
                  value={{ t_name: formik.values.teacher }}
                  onChange={(event, value) => {
                    formik.setFieldValue("teacher", value.t_name);
                    formik.setFieldValue("teacherid", value.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="teacher"
                      id="teacher"
                      label="Teacher"
                      margin="normal"
                      error={
                        formik.touched.teacher && Boolean(formik.errors.teacher)
                      }
                      helperText={
                        formik.touched.teacher && formik.errors.teacher
                      }
                    />
                  )}
                />
              </div>
              <ColorButton type="submit">next</ColorButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
