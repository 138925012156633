import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  CardActions,
  CardHeader,
  CircularProgress,
  Collapse,
  IconButton,
  Modal,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import service from "../../service/Service";
// import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import imageCompression from "browser-image-compression";
import moment from "moment/moment";
import Resizer from "react-image-file-resizer";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MapIcon from "@mui/icons-material/Map";

import { ExportToExel } from "../../ExportToExcel";
import { PublicURLImages } from "../../config";
const validationSchema = yup.object({
  event_logo_base: yup
    .string("Image is required")
    .required("Event Logo is required"),
  event_logo_ext: yup
    .string("Image is required")
    .required("Event Logo is required"),
  event_name: yup.string("Enter Event Name").required("Event Name Required*"),
  event_city_name: yup
    .string("Enter Event City")
    .required("Event city is  Required*"),
  event_location_name: yup
    .string("Enter Event Location")
    .required("Event Location is  Required*"),
  event_date: yup
    .string("Enter Event Date")
    .required("Event Date is  Required*"),
  event_description: yup
    .string("Enter Event Description")
    .required("Event Description is  Required*"),
  event_edition: yup
    .string("Enter Event Edition")
    .required("Event Edition is  Required*"),
  event_location_google_maps_url: yup
    .string("Please Provide Event Google Map url")
    .required("Google Map URL Required*"),
});

export const ManagEvent = () => {
  const [getEvent, SetEvent] = useState([]);
  const [responsestatus, Setresponsestatus] = useState("");
  const [responsedelete, Setresponsedelete] = useState("");
  const [searchTxt, setsearchTxt] = useState("");
  const [newerror, Seterror] = React.useState("");
  const [logo, setlogo] = useState("");
  const [logoext, setlogoext] = useState("");
  const [createnew, setcreatenew] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [expandedRows, setExpandedRows] = useState({});
  const [row, setrow] = useState("");
  const formik = useFormik({
    initialValues: {
      event_name: "",
      event_city_name: "",
      event_description: "",
      event_date: "",
      event_edition: "",
      event_location_google_maps_url: "",
      event_logo_base: "",
      event_logo_ext: "",
      event_location_name: "",
      is_active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      RegisterEvent(values);
    },
  });

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  const notify = () => toast.success("Event Created Successfully");
  const notifyfordelete = () => toast.error("Event Deleted Successfully");
  const notifystatus = (MSG) => toast.error(MSG || "Status Updated");

  const RegisterEvent = (values) => {
    service
      .post(`create-event`, values)
      .then((res) => {
        Setresponsestatus(res.data.status);
        notify();
        LoadData();
        setcreatenew(false);
        formik.resetForm();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const handleOpen = (row) => {
    setrow(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteEvent = (id) => {
    service
      .get(`delete-event/${id}`)
      .then((res) => {
        // console.log(res);
        Setresponsedelete(res.data.status);
        // console.log(res.data.status);
        notifyfordelete();
        LoadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const LoadData = () => {
    service
      .get("all-events")
      .then((res) => {
        SetEvent(res.data);
      })
      .catch((err) => {});
    console.log("Getting All DAta");
  };
  React.useEffect(() => {
    LoadData();
  }, []);

  const Logo = async (event) => {
    let file = event.target.files[0];
    if (file.width > 150 || file.height > 150) {
      Seterror("Image dimensions should be less than 150x150 pixels.");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 150, // Maximum dimensions
      useWebWorker: true, // Enable web worker for faster compression (optional)
    };

    const compressedImage = await imageCompression(file, options);

    try {
      Resizer.imageFileResizer(
        compressedImage,
        150, // New width
        150, // New height
        "JPEG", // Image format
        100, // Image quality (0-100)
        0, // Rotation (optional)
        async (uri) => {
          // 'uri' is the resized image in base64 format
          let photoExt = file.type.split("/")[1];
          setlogoext(photoExt);
          formik.setFieldValue("event_logo_ext", photoExt);
          formik.setFieldValue("event_logo_base", uri.split(",")[1]);
          setlogo(uri);
        },
        "base64"
      );
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  const eventstatusHandle = (id, status) => {
    if (status) {
      service
        .post(`active-event-status/${id}`)
        .then((res) => {
          console.log(res);
          LoadData();
          notifystatus(res.data.message);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } else {
      service
        .post(`deactive-event-status/${id}`)
        .then((res) => {
          console.log(res);
          LoadData();
          notifystatus(res.data.message);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };
  return (
    <div className="grandContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginRight: "10%",
        }}
      >
        <ColorButton onClick={() => setcreatenew(!createnew)}>
          {!createnew ? "+ Create Event" : "Cancel"}
        </ColorButton>
      </div>
      {createnew ? (
        <>
          <div className="FormContainer">
            <div className="innerContainer">
              <p className="formTitle">Create Event</p>
              <form onSubmit={formik.handleSubmit}>
                <p className="subTitle">Provide Event Logo</p>

                {formik.values.event_logo_ext === "" ? (
                  <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton
                        className="logoImage"
                        variant="rectangular"
                        width={150}
                        height={150}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/${formik.values.event_logo_ext};base64,${formik.values.event_logo_base}`}
                      alt="preview image"
                      className="logoImage"
                      style={{ margin: "15px auto" }}
                    />
                  </>
                )}
                <h3>150x150</h3>
                <ColorButton variant="contained" component="label">
                  Select
                  <input hidden accept="image/*" type="file" onChange={Logo} />
                </ColorButton>

                <div className="innerGroup">
                  <p>Event Name</p>
                  <TextField
                    id="event_name"
                    name="event_name"
                    label="Event Name"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("event_name")}
                    onBlur={formik.handleChange("event_name")}
                    value={formik.values.event_name}
                    error={
                      formik.touched.event_name &&
                      Boolean(formik.errors.event_name)
                    }
                    helperText={
                      formik.touched.event_name && formik.errors.event_name
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event Description</p>
                  <TextField
                    id="event_description"
                    name="event_description"
                    label="Event Description"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("event_description")}
                    onBlur={formik.handleChange("event_description")}
                    value={formik.values.event_description}
                    error={
                      formik.touched.event_description &&
                      Boolean(formik.errors.event_description)
                    }
                    helperText={
                      formik.touched.event_description &&
                      formik.errors.event_description
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event Date</p>
                  <TextField
                    id="event_date"
                    type="date"
                    name="event_date"
                    margin="normal"
                    className="input"
                    onChange={(e) => {
                      let d = e.target.value;
                      let currdate = moment(d).format("DD-MM-YYYY");
                      console.log(currdate);
                      formik.setFieldValue("event_date", currdate);
                    }}
                    onBlur={formik.handleChange("event_date")}
                    error={
                      formik.touched.event_date &&
                      Boolean(formik.errors.event_date)
                    }
                    helperText={
                      formik.touched.event_date && formik.errors.event_date
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event Edition</p>
                  <TextField
                    id="event_edition"
                    name="event_edition"
                    label="Event Edition"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("event_edition")}
                    onBlur={formik.handleChange("event_edition")}
                    value={formik.values.event_edition}
                    error={
                      formik.touched.event_edition &&
                      Boolean(formik.errors.event_edition)
                    }
                    helperText={
                      formik.touched.event_edition &&
                      formik.errors.event_edition
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event City</p>
                  <TextField
                    id="event_city_name"
                    name="event_city_name"
                    label="Event City"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("event_city_name")}
                    onBlur={formik.handleChange("event_city_name")}
                    value={formik.values.event_city_name}
                    error={
                      formik.touched.event_city_name &&
                      Boolean(formik.errors.event_city_name)
                    }
                    helperText={
                      formik.touched.event_city_name &&
                      formik.errors.event_city_name
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event Location</p>
                  <TextField
                    id="event_location_name"
                    name="event_location_name"
                    label="Event Location Name"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("event_location_name")}
                    onBlur={formik.handleChange("event_location_name")}
                    value={formik.values.event_location_name}
                    error={
                      formik.touched.event_location_name &&
                      Boolean(formik.errors.event_location_name)
                    }
                    helperText={
                      formik.touched.event_location_name &&
                      formik.errors.event_location_name
                    }
                  />
                </div>
                <div className="innerGroup">
                  <p>Event Map Url</p>
                  <TextField
                    id="event_location_google_maps_url"
                    name="event_location_google_maps_url"
                    label="Event Map Link"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange(
                      "event_location_google_maps_url"
                    )}
                    onBlur={formik.handleChange(
                      "event_location_google_maps_url"
                    )}
                    value={formik.values.event_location_google_maps_url}
                    error={
                      formik.touched.event_location_google_maps_url &&
                      Boolean(formik.errors.event_location_google_maps_url)
                    }
                    helperText={
                      formik.touched.event_location_google_maps_url &&
                      formik.errors.event_location_google_maps_url
                    }
                  />
                </div>
                {/* <div className="innerGroup">
              <p>Event Logo</p>
              <TextField
                id="event_logo"
                name="event_logo"
                label="Event Logo"
                margin="normal"
                className="input"
                onChange={formik.handleChange("e_map")}
                value={formik.values.event_logo}
                error={
                  formik.touched.event_logo && Boolean(formik.errors.event_logo)
                }
                helperText={
                  formik.touched.event_logo && formik.errors.event_logo
                }
              />
            </div> */}
                <ColorButton type="submit">Create</ColorButton>
              </form>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div>
        <p
          style={{
            fontSize: 30,
            letterspacing: 2,
            fontWeight: "bold",
            color: "#169b8a",
            opacity: 0.8,
            marginBottom: 10,
          }}
        >
          All Events
        </p>
      </div>
      {getEvent?.length !== 0 ? (
        <div
          className="cardContainer"
          style={{
            padding: "5px",
            width: "80%",
          }}
        >
          <TextField
            id="search"
            name="search"
            label="Search Events"
            margin="normal"
            className="Textinput"
            value={searchTxt}
            onChange={handleChangeSearch}
          />
          <TableContainer>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => ExportToExel(getEvent, "Events")}
                variant="contained"
                color="info"
                style={{ marginBottom: 10 }}
              >
                Export
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sno</TableCell>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>More</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {getEvent &&
                    getEvent
                      .filter((e) =>
                        e?.event_name
                          .toLowerCase()
                          .includes(searchTxt.toLowerCase())
                      )
                      .map((row, i) => (
                        <>
                          <TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.event_name}</TableCell>
                            <TableCell>
                              <Switch
                                checked={row.is_active ? true : false}
                                onChange={() =>
                                  eventstatusHandle(row.id, !row.is_active)
                                }
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => deleteEvent(row.id)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => toggleRow(row.id)}
                              >
                                {/* {expandedRows[row.id] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )} */}

                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleOpen(row);
                                  }}
                                >
                                  View
                                </Button>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                      {row.event_edition}
                    </Avatar>
                  }
                  action={<IconButton aria-label="settings"></IconButton>}
                  title={row.event_city_name}
                  subheader={row.event_date}
                />

                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${PublicURLImages + "/events/" + row.event_logo}`}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {row.event_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {row.event_description}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      component="div"
                    >
                      Event Location: {row.event_location_name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    href={row.event_location_google_maps_url}
                  >
                    Location
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Modal>
        </div>
      ) : (
        <CircularProgress />
      )}

      <ToastContainer />
    </div>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};
