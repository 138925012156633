import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExportToExel } from "../ExportToExcel";
import { BaseURL } from "../config";
import service from "../service/Service";

export const AllStudent = () => {
  const token = localStorage.getItem("j");
  // const [bcolor, Setbcolor] = React.useState("rgba(255, 255, 255, 0.4)");
  const [getstudents, Setstudents] = useState([]);
  const navigate = useNavigate();
  const [searchTxt, setsearchTxt] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25];
  const [stdcount, setstdcount] = useState(0);
  const [allstd, setallstd] = useState([]);
  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const GetAllStudents = () => {
    service.get(`all-students`).then((res) => {
      let { students } = res.data;
      setstdcount(students?.length);
      Setstudents(
        students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
      setallstd(students);
      // console.log(students);
    });
  };
  React.useEffect(() => {
    GetAllStudents();
  }, [page, rowsPerPage]);

  const handleChange = (id) => {
    service.post(`${BaseURL}update-student-status/${id}`).then((response) => {
      // console.log(response.status);
      // Setbcolor("silver");
      GetAllStudents();
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="grandContainer">
      <div className="FormContainer" style={{ width: "95%" }}>
        <div className="innerContainer">
          <p className="formTitle" style={{ margin: 10 }}>
            All Students
          </p>
        </div>
        <div
          className="cardContainer"
          style={{
            width: 1000,
            alignContent: "center",
            marginLeft: "47.5px",
          }}
        >
          <div>
            <span
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: 20,
                padding: 20,
              }}
            >
              Total Students : {stdcount}
            </span>
          </div>
          <TextField
            id="search"
            name="search"
            label="Search Students"
            margin="normal"
            className="Textinput"
            value={searchTxt}
            onChange={handleChangeSearch}
          />
          {getstudents?.length !== 0 ? (
            <>
              <TableContainer>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={() => ExportToExel(getstudents)}
                    variant="contained"
                    color="info"
                    style={{ marginBottom: 10 }}
                  >
                    Export
                  </Button>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Students</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {searchTxt !== "" ? (
                        <>
                          {allstd &&
                          allstd.filter((e) =>
                            e.std_name
                              .toLowerCase()
                              .includes(searchTxt.toLowerCase())
                          ).length == 0 ? (
                            <>
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  marginTop: 20,
                                }}
                              >
                                No Students Found With Your Search
                              </p>
                            </>
                          ) : (
                            <>
                              {allstd &&
                                allstd
                                  .filter((e) =>
                                    e.std_name
                                      .toLowerCase()
                                      .includes(searchTxt.toLowerCase())
                                  )
                                  .map((row, i) => (
                                    <TableRow>
                                      <TableCell>{++i}</TableCell>
                                      <TableCell>{row.std_name}</TableCell>
                                      <TableCell>
                                        <Switch
                                          checked={
                                            row.status === "active"
                                              ? true
                                              : false
                                          }
                                          onChange={() => handleChange(row.id)}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {getstudents.map((row, i) => (
                            <TableRow>
                              <TableCell>{++i}</TableCell>
                              <TableCell>{row.std_name}</TableCell>
                              <TableCell>
                                <Switch
                                  checked={
                                    row.status === "active" ? true : false
                                  }
                                  onChange={() => handleChange(row.id)}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={stdcount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </div>
  );
};
