import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { LoadingButton } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import service from "../../service/Service";

const validationSchema = yup.object({
  TeamName: yup
    .string("Add your Team Members")
    .required("Team Members is required"),
});

export const TeamDetails = () => {
  const dispatch = useDispatch();
  const [loadingbtn, Setloadinbtn] = useState(false);
  const myAllData = useSelector((state) => state.Alldata.data);

  const [getteam, setteam] = useState([]);

  const formik = useFormik({
    initialValues: {
      TeamName: myAllData.TeamName || myAllData.team_name || "",
      TeamMember: myAllData.TeamMember || myAllData.team || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      dispatch({ type: "next", payload: true });
      dispatch({
        type: "addxyz",
        payload: values,
      });
    },
  });
  const [getstudents, Setstudents] = useState([]);

  const GetAllStudents = () => {
    let role = localStorage.getItem("role");
    if (role === "xxst") {
      service.get(`team-members`).then((res) => {
        let { students } = res.data;
        Setstudents(students);
        // console.log(students);
      });
    } else if (role === "xxca") {
      service.get(`team-member-by-center`).then((res) => {
        let { students } = res.data;
        Setstudents(students);
      });
    }
  };

  React.useEffect(() => {
    GetAllStudents();
  }, []);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Team Details</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Team Name</p>
                <TextField
                  id="TeamName"
                  name="TeamName"
                  label="Your Team Name"
                  margin="normal"
                  placeholder="Enter Team Name"
                  className="input"
                  onChange={formik.handleChange}
                  value={formik.values.TeamName}
                  error={
                    formik.touched.TeamName && Boolean(formik.errors.TeamName)
                  }
                  helperText={formik.touched.TeamName && formik.errors.TeamName}
                />
              </div>

              <div className="innerGroup">
                <p>ADD Team Members</p>
                <Autocomplete
                  className="input"
                  limitTags={3}
                  id="multiple-limit-tags"
                  options={getstudents.map((e) => e)}
                  multiple
                  getOptionLabel={(option) => `std${option.std_id}`}
                  value={formik.values.TeamMember}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("TeamMember", [...newValue]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select"
                      placeholder="Select Team Members"
                    />
                  )}
                  sx={{ width: "500px" }}
                />
                {/* <p>DATA : {getteam}</p> */}
              </div>
              <LoadingButton
                loading={loadingbtn}
                variant="text"
                type="submit"
                sx={{ backgroundColor: "#169b8a", color: "white" }}
              >
                next
              </LoadingButton>
              {/* <ColorButton variant="text" type="submit">next</ColorButton> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
