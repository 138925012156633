import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const validationSchema = yup.object({
  RelaseType: yup
    .string("Enter your Project Type")
    .required("Project Type Required"),
  access: yup
    .string("Enter Your Project Access")
    .required("Project Access Requireds"),
  projectbase: yup.string("").when("RelaseType", {
    is: "1",
    then: yup.string().required(),
  }),
  gitHubUrl: yup.string("").when("RelaseType", {
    is: "0",
    then: yup
      .string("Please add Url")
      .url("Please provide a valid URL.")
      .required("Github Url is Required"),
  }),
});

export const ReleaseDetails = () => {
  const [Files, SetFiles] = React.useState("");

  const [projectbase, setprojectbase] = useState("");
  const [projectext, setprjectext] = useState("");
  const [error, Seterror] = useState("");
  const myAllData = useSelector((state) => state.Alldata.data);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      RelaseType: myAllData.RelaseType || "0",
      access: myAllData.access || myAllData.access_details || "",
      projectbase: myAllData.projectbase || "",
      projectext: myAllData.projectext || "",
      gitHubUrl: myAllData.gitHubUrl || myAllData.file_path || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values, "formValues1");
      dispatch({
        type: "addxyz",
        payload: values,
      });
      dispatch({ type: "next", payload: true });
    },
  });

  useEffect(() => {
    // console.log(myAllData, "formValues");
  }, [myAllData]);

  const ProjectFiles = async (event) => {
    if (event.target.files[0].size > 100000000) {
      Seterror("File is too big pls upload Max 100MB");
    } else if (event.target.files && event.target.files[0]) {
      let base64 = await convertBase64(event.target.files[0]);
      setprojectbase(base64);
      formik.setFieldValue("projectbase", base64);
      base64 = base64.split(",")[1];
      let filename_ = event.target.files[0].name;
      let ffile_arr = filename_.split(".");
      let ext = ffile_arr[ffile_arr.length - 1];
      setprjectext(ext);
      formik.setFieldValue("projectext", ext);
      SetFiles(URL.createObjectURL(event.target.files[0]));
      Seterror("");
    } else {
      console.log("Logo Error");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Release Details</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Project Access</p>
                <TextField
                  id="access"
                  name="access"
                  label="Access Details"
                  multiline={true}
                  placeholder="Please Provide Your Project Access Details For Testing Purpose (if any)"
                  minRows="2"
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange}
                  value={formik.values.access}
                  error={formik.touched.access && Boolean(formik.errors.access)}
                  helperText={formik.touched.access && formik.errors.access}
                />
              </div>

              <div className="innerGroup">
                <p>Release Type</p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Release Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RelaseType"
                    name="RelaseType"
                    value={formik.values.RelaseType}
                    label="Release Type"
                    onChange={formik.handleChange}
                    disabled
                  >
                    <MenuItem value={"0"}>Github Url</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {formik.values.RelaseType === "0" ? (
                <>
                  <div className="innerGroup">
                    <p>GitHub URL</p>
                    <TextField
                      id="gitHubUrl"
                      name="gitHubUrl"
                      label="Github project URL"
                      margin="normal"
                      className="input"
                      type={"url"}
                      onChange={formik.handleChange}
                      value={formik.values.gitHubUrl}
                      error={
                        formik.touched.gitHubUrl &&
                        Boolean(formik.errors.gitHubUrl)
                      }
                      helperText={
                        formik.touched.gitHubUrl && formik.errors.gitHubUrl
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  {formik.values.RelaseType === "1" ? (
                    <>
                      <p className="subTitle">Select Project Files</p>
                      <ColorButton variant="contained" component="label">
                        Upload File
                        <input
                          hidden
                          type="file"
                          multiple
                          onChange={ProjectFiles}
                          accept=".apk"
                        />
                      </ColorButton>
                      <p className="errorText">{error}</p>
                      {formik.values.projectbase !== "" ? (
                        <div>
                          <p className="successText">File Selected</p>{" "}
                          <CheckCircleIcon className="imageIcon done" />
                        </div>
                      ) : (
                        <div>
                          <p className="errorText">No Files Selected</p>
                          <CancelIcon className="imageIcon" />
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <ColorButton type="Submit">next</ColorButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
