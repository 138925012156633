import { Box, CircularProgress, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import service from "../../service/Service";

export const CenterManageProject = () => {
  const [projectsxyz, setprojects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchval, setSearchVal] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    service
      .get(`center-pending-projects`)
      .then((res) => {
        setLoading(false);
        //console.log(res.data);
        const { unapproved_projects } = res.data;
        setprojects(unapproved_projects);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleserach = (e) => {
    // console.log(e.target.value);
    const val = e.target.value;
    setSearchVal(val);
  };

  return (
    <>
      {!loading ? (
        <>
          {projectsxyz.length === 0 ? (
            <>
              <div
                style={{
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "#aaa" }}>No Data Found...</h2>
              </div>
            </>
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ width: "90%", margin: "10px" }}
                  type="search"
                  onChange={handleserach}
                  placeholder="Search"
                />
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sno.</TableCell>
                      <TableCell>Center Name</TableCell>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Technology</TableCell>
                      <TableCell>Publish Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectsxyz
                      .filter(
                        (e) =>
                          e.p_name.toLowerCase().includes(searchval) ||
                          e.center_name.toLowerCase().includes(searchval) ||
                          e.cat.toLowerCase().includes(searchval) ||
                          e.tech.toLowerCase().includes(searchval)
                      )
                      .map((e, i) => (
                        <TableRow key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{e.center_name}</TableCell>
                          <TableCell>{e.p_name}</TableCell>
                          <TableCell>{e.cat}</TableCell>
                          <TableCell>{e.tech}</TableCell>
                          <TableCell>11/10/2022</TableCell>
                          <TableCell>
                            {e.status === 0
                              ? "Pending with center"
                              : e.status === 1
                              ? "Pending with FSO"
                              : e.status === 2
                              ? "Publish"
                              : e.status === 3
                              ? "Rejected By FSO"
                              : e.status === 4
                              ? "Rejected By Center"
                              : ""}
                          </TableCell>
                          <TableCell>
                            <Link to={`/admin/projectdetails/${e.p_id}`}>
                              <Button variant="contained">View</Button>
                            </Link>
                            {e.status === 3 ? (
                              <>
                                <Button
                                  variant="contained"
                                  color="error"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    dispatch({ type: "addxyz", payload: e });
                                    navigate("/admin/publish");
                                  }}
                                >
                                  Update
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};
