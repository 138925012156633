import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  TablePagination,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { ExportToExel } from "../../ExportToExcel";
import service from "../../service/Service";

const Overallproject = () => {
  const [projectsxyz, setprojects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchval, setSearchVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25];
  const [allprojects, setallprojects] = useState([]);
  const [projectscount, setprojectscount] = useState(0);
  
  useEffect(() => {
    getAll();
  }, [page, rowsPerPage]);

  const getAll = () => {
    setLoading(true);
    service
      .get(`all-projects`)
      .then((res) => {
        setLoading(false);
        const { all_projects } = res.data;

        setprojects(
          all_projects.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        );
        setallprojects(all_projects);
        setprojectscount(all_projects.length);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleserach = (e) => {
    // console.log(e.target.value);
    const val = e.target.value;
    setSearchVal(val);
  };

  return (
    <>
      {!loading ? (
        <>
          {projectsxyz.length === 0 ? (
            <>
              <div
                style={{
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "#aaa" }}></h2>
              </div>
            </>
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <TextField
                  id="search"
                  name="search"
                  label="Search Projects"
                  margin="normal"
                  className="Textinput"
                  value={searchval}
                  onChange={handleserach}

                  // sx={{ width: "90%", margin: "10px" }}
                  // type="search"
                  // value={searchval}
                  // onChange={handleserach}
                  // placeholder="Search"
                />
              </Box>
              <TableContainer component={Paper}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={() => ExportToExel(allprojects, "All Projects")}
                    variant="contained"
                    color="info"
                    style={{ marginBottom: 10 }}
                  >
                    Export
                  </Button>
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sno.</TableCell>
                      <TableCell>Center Name</TableCell>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Technology</TableCell>
                      <TableCell>Publish Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchval !== "" ? (
                      <>
                        {allprojects &&
                          allprojects
                            .filter(
                              (e) =>
                                e.p_name.toLowerCase().includes(searchval) ||
                                e.center_name
                                  .toLowerCase()
                                  .includes(searchval) ||
                                e.cat.toLowerCase().includes(searchval) ||
                                e.tech.toLowerCase().includes(searchval)
                            )
                            .map((e, i) => (
                              <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{e.center_name}CCcc</TableCell>
                                <TableCell>{e.p_name}</TableCell>
                                <TableCell>{e.cat}</TableCell>
                                <TableCell>{e.tech}</TableCell>
                                <TableCell>{e.created_at}</TableCell>
                                <TableCell>
                                  {e.status === 0
                                    ? "Pending with center"
                                    : e.status === 1
                                    ? "Pending with FSO"
                                    : e.status === 2
                                    ? "Published"
                                    : e.status === 3
                                    ? "Rejected By FSO"
                                    : e.status === 4
                                    ? "Rejected By Center"
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  <Link
                                    to={`/SuperAdmin/projectdetails/${e.p_id}`}
                                  >
                                    <Button variant="contained">View</Button>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                      </>
                    ) : (
                      <>
                        {projectsxyz.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{e.center_name}</TableCell>
                            <TableCell>{e.p_name}</TableCell>
                            <TableCell>{e.cat.trim()}</TableCell>
                            <TableCell>
                              {e.tech.trim() || "No Tech Found"}
                            </TableCell>
                            <TableCell>{e.created_at}</TableCell>
                            <TableCell>
                              {e.status === 0
                                ? "Pending with center"
                                : e.status === 1
                                ? "Pending with FSO"
                                : e.status === 2
                                ? "Published"
                                : e.status === 3
                                ? "Rejected By FSO"
                                : e.status === 4
                                ? "Rejected By Center"
                                : ""}
                            </TableCell>
                            <TableCell>
                              <Link to={`/SuperAdmin/projectdetails/${e.p_id}`}>
                                <Button variant="contained">View</Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={projectscount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};

export default Overallproject;
