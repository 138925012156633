import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
import { SuperAdmin } from "./SuperAdmin";
import { ADDAdmins } from "./components/ADDAdmins";
import { Approved } from "./components/Approved";
import ManageAdmin from "./components/ManageAdmin";
import { PendingProjects } from "./components/ProjectsComponent/CenterPendingProjects";
import Publish from "./components/Publish";
import { Status } from "./components/Status";
import { ViewProjects } from "./components/ViewProjects";

import { AllStudent } from "./components/AllStudents";

import { AddTeacher } from "./components/ADDTeachers";
import { AddStudents } from "./components/AddStudents";
import { AllCenters } from "./components/Allcenters";
import { ManageStudents } from "./components/ManagStudents";
import ManageTeacher from "./components/ManageTeacher";
import { Categories } from "./components/Project Types/Categories";
import { Industry } from "./components/Project Types/Industry";
import { Technology } from "./components/Project Types/Technology";
import { StudentProfile } from "./components/StudentProfile";
import { WaitingForPublish } from "./components/WaitingForpublish";
import Middlwware from "./service/Middlwware";

import { CenterManageProject } from "./components/ProjectsComponent/CenterManageProject";
import { ViewProjectDetails } from "./components/ViewProjectDetails";

import { ADMIN } from "./ADMIN";
import { ManagEvent } from "./components/superadmin/CreateEvent";
import Overallproject from "./components/superadmin/Overallproject";
const GuardedRoute = () => {
  const navigate = useNavigate();
  const storedRole = localStorage.getItem("role");
  const token = localStorage.getItem("j");

  useEffect(() => {
    if (token == null) {
      navigate("/");
    } else {
      if (storedRole === "xxca") {
        navigate("Admin");
      } else if (storedRole === "xxsa") {
        navigate("Superadmin");
      } else if (storedRole === "xxst") {
        navigate("dashboard");
      }
    }
  }, [storedRole, token]);

  return (
    <Routes>
      <Route path="admin" element={<ADMIN />}>
        <Route path="" element={<Navigate to="addstudents" />} />
        <Route path="projectdetails/:id" element={<ViewProjectDetails />} />
        <Route path="addstudents" element={<AddStudents />} />
        <Route path="PublishedProjects" element={<ViewProjects />} />
        <Route path="approvedprojects" element={<Approved />} />
        <Route path="pendingproject" element={<CenterManageProject />} />
        <Route path="managestudents" element={<ManageStudents />} />
        <Route path="manageteachers" element={<ManageTeacher />} />
        <Route path="addteachers" element={<AddTeacher />} />
        <Route path="Publish" element={<Publish />} />
      </Route>

      <Route path="/" element={<Login />} />
      <Route path="/link/:email/:name" element={<Middlwware />} />

      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="projectdetails/:id" element={<ViewProjectDetails />} />
        <Route path="" element={<Navigate to="yourprofile" />} />
        <Route path="Publish" element={<Publish />} />
        <Route path="yourprofile" element={<StudentProfile />} />
        <Route path="myprojects" element={<Status />} />
      </Route>

      <Route path="/Superadmin" element={<SuperAdmin />}>
        <Route path="" element={<Navigate to="manageevent" />} />
        <Route path="createcenter" element={<ADDAdmins />} />
        <Route path="managecenters" element={<ManageAdmin />} />
        <Route path="projectdetails/:id" element={<ViewProjectDetails />} />
        <Route path="overallprojects" element={<Overallproject />} />
        <Route path="manageevent" element={<ManagEvent />} />
        <Route path="pendingprojects" element={<PendingProjects />} />
        <Route path="revisedprojects" element={<WaitingForPublish />} />
        <Route path="managestudents" element={<AllStudent />} />
        <Route path="allcenters" element={<AllCenters />} />
        <Route path="categories" element={<Categories />} />
        <Route path="technologies" element={<Technology />} />
        <Route path="industries" element={<Industry />} />
      </Route>
    </Routes>
  );
};

export default GuardedRoute;
