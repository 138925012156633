import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { PublicURLImages } from "../../config";

const validationSchema = yup.object({
  video: yup
    .string("Vide URL Required")
    .url("Please Provide a valid URL")
    .matches(
      /http.*?.*\/\/.*?youtube\.\w+?\w\.?\/watch\?v=(\w+|\d+)/g,
      "Only youtube videos are allowed"
    )
    .required("Video Project Required"),
  base1: yup.string("Image is required").required("Image is required"),
  base2: yup.string("Image is required").required("Image is required"),
  base3: yup.string("Image is required").required("Image is required"),
  logobase: yup.string("Image is required").required("Image is required"),
  logoext: yup.string("Image is required").required("Image is required"),
  imageex3: yup.string("Image is required").required("Image is required"),
  imageex2: yup.string("Image is required").required("Image is required"),
  imageex1: yup.string("Image is required").required("Image is required"),
});

export const Projectgraphics = () => {
  const dispatch = useDispatch();
  const [logo, setlogo] = React.useState("");
  const [logobase, setlogobase] = React.useState("");
  const myAllData = useSelector((state) => state.Alldata.data);

  const [image1, setImage1] = React.useState("");
  const [base1, setbase1] = React.useState("");
  const [image2, setImage2] = React.useState("");
  const [base2, setbase2] = React.useState("");
  const [image3, setImage3] = React.useState("");
  const [base3, setbase3] = React.useState("");
  const [video, Setvideo] = React.useState("");
  const [newerror, Seterror] = React.useState("");

  //EXT
  const [logoext, setlogoext] = useState("");
  const [imageex1, setimageex1] = useState("");
  const [imageex2, setimageex2] = useState("");
  const [imageex3, setimageext3] = useState("");

  const [spining1, setspining1] = useState(true);
  const [spining2, setspining2] = useState(true);
  const [spining3, setspining3] = useState(true);
  const [spining4, setspining4] = useState(true);

  useEffect(() => {
    // console.log(myAllData, "PROJECT DATA FORR UPDATE");
    if (myAllData.logo) {
      toDataUrl(`${PublicURLImages}projects/${myAllData.logo}`, (e) => {
        let ext = myAllData.logo?.split(".")[1];
        let a = e.split(",")[1];
        formik.setFieldValue("logobase", a);
        formik.setFieldValue("logoext", ext);
        setspining1(false);
      });
    }
    if (myAllData.images) {
      toDataUrl(`${PublicURLImages}projects/${myAllData.images[0]}`, (e) => {
        let ext = myAllData.logo?.split(".")[1];
        let a = e.split(",")[1];
        formik.setFieldValue("base1", a);
        formik.setFieldValue("imageex1", ext);
        setspining2(false);
      });
    }
    if (myAllData.images) {
      toDataUrl(`${PublicURLImages}projects/${myAllData.images[1]}`, (e) => {
        let ext = myAllData.logo?.split(".")[1];
        let a = e.split(",")[1];
        formik.setFieldValue("base2", a);
        formik.setFieldValue("imageex2", ext);
        setspining3(false);
      });
    }
    if (myAllData.images) {
      toDataUrl(`${PublicURLImages}projects/${myAllData.images[2]}`, (e) => {
        let ext = myAllData.logo?.split(".")[1];
        let a = e.split(",")[1];
        formik.setFieldValue("base3", a);
        formik.setFieldValue("imageex3", ext);
        setspining4(false);
      });
    }
  }, []);

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const formik = useFormik({
    initialValues: {
      video: myAllData.video || "",
      logobase: myAllData.logobase || "",
      base1: myAllData.base1 || "",
      base2: myAllData.base2 || "",
      base3: myAllData.base3 || "",
      logoext: myAllData.logoext || "",
      imageex1: myAllData.imageex1 || "",
      imageex2: myAllData.imageex2 || "",
      imageex3: myAllData.imageex3 || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      dispatch({
        type: "addxyz",
        payload: values,
      });
      dispatch({ type: "next", payload: true });
    },
  });

  const Logo = async (event) => {
    let file = event.target.files[0];
    if (file.width > 150 || file.height > 150) {
      Seterror("Image dimensions should be less than 150x150 pixels.");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 150, // Maximum dimensions
      useWebWorker: true, // Enable web worker for faster compression (optional)
    };

    const compressedImage = await imageCompression(file, options);

    try {
      Resizer.imageFileResizer(
        compressedImage,
        150, // New width
        150, // New height
        "JPEG", // Image format
        100, // Image quality (0-100)
        0, // Rotation (optional)
        async (uri) => {
          // 'uri' is the resized image in base64 format
          let photoExt = file.type.split("/")[1];
          setlogoext(photoExt);
          formik.setFieldValue("logoext", photoExt);
          formik.setFieldValue("logobase", uri.split(",")[1]);
          setlogo(uri);
        },
        "base64" // Output format ('base64', 'blob', 'file', or 'dataURL')
      );
    } catch (error) {
      console.error("Error processing image:", error);
    }
    // if (event.target.files[0].size > 1000000) {
    //   Seterror("File is too big pls upload Max 1MB");
    // } else if (event.target.files && event.target.files[0]) {
    //   let base64 = await convertBase64(event.target.files[0]);
    //   base64 = base64.split(",")[1];
    //   // setlogobase(base64);
    //   formik.setFieldValue("logobase", base64);
    //   console.log(base64);

    //   let photoExt = event.target.files[0].type.split("/")[1];
    //   setlogoext(photoExt);
    //   formik.setFieldValue("logoext", photoExt);
    //   setlogo(URL.createObjectURL(event.target.files[0]));
    //   Seterror("");
    // } else {
    //   console.log("Logo Error");
    // }
  };
  const ProjectImage1 = async (event) => {
    let file = event.target.files[0];
    if (file.width > 800 || file.height > 600) {
      Seterror("Image dimensions should be less than 800X600 pixels.");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 800, // Maximum dimensions
      useWebWorker: true, // Enable web worker for faster compression (optional)
    };

    const compressedImage = await imageCompression(file, options);

    try {
      Resizer.imageFileResizer(
        compressedImage,
        800, // New width
        600, // New height
        "JPEG", // Image format
        100, // Image quality (0-100)
        0, // Rotation (optional)
        async (uri) => {
          // 'uri' is the resized image in base64 format
          let photoExt = file.type.split("/")[1];
          setimageex1(photoExt);
          formik.setFieldValue("imageex1", photoExt);
          formik.setFieldValue("base1", uri.split(",")[1]);
          setImage1(uri);
        },
        "base64" // Output format ('base64', 'blob', 'file', or 'dataURL')
      );
    } catch (error) {
      console.error("Error processing image:", error);
    }

    // if (event.target.files[0].size > 1000000) {
    //   Seterror("File size should be less than 1MB");
    // } else if (event.target.files && event.target.files[0]) {
    //   let base64 = await convertBase64(event.target.files[0]);
    //   base64 = base64.split(",")[1];
    //   setbase1(base64);
    //   formik.setFieldValue("base1", base64);

    //   let photoExt = event.target.files[0].type.split("/")[1];
    //   setimageex1(photoExt);
    //   formik.setFieldValue("imageex1", photoExt);
    //   console.log("base", base64);
    //   setImage1(URL.createObjectURL(event.target.files[0]));
    //   Seterror("");
    // } else {
    //   console.log("Logo Error");
    // }
  };
  const ProjectImage2 = async (event) => {
    let file = event.target.files[0];
    if (file.width > 800 || file.height > 600) {
      Seterror("Image dimensions should be less than 800X600 pixels.");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 800, // Maximum dimensions
      useWebWorker: true, // Enable web worker for faster compression (optional)
    };

    const compressedImage = await imageCompression(file, options);

    try {
      Resizer.imageFileResizer(
        compressedImage,
        800, // New width
        600, // New height
        "JPEG", // Image format
        100, // Image quality (0-100)
        0, // Rotation (optional)
        async (uri) => {
          // 'uri' is the resized image in base64 format
          let photoExt = file.type.split("/")[1];
          setimageex2(photoExt);
          formik.setFieldValue("imageex2", photoExt);
          formik.setFieldValue("base2", uri.split(",")[1]);
          setImage2(uri);
        },
        "base64" // Output format ('base64', 'blob', 'file', or 'dataURL')
      );
    } catch (error) {
      console.error("Error processing image:", error);
    }
    // if (event.target.files[0].size > 1000000) {
    //   Seterror("File size should be less than 1MB");
    // } else if (event.target.files && event.target.files[0]) {
    //   let base64 = await convertBase64(event.target.files[0]);
    //   base64 = base64.split(",")[1];
    //   formik.setFieldValue("base2", base64);
    //   setbase2(base64);
    //   let photoExt = event.target.files[0].type.split("/")[1];
    //   setimageex2(photoExt);
    //   formik.setFieldValue("imageex2", photoExt);
    //   setImage2(URL.createObjectURL(event.target.files[0]));
    //   Seterror("");
    // } else {
    //   console.log("Logo Error");
    // }
  };
  const ProjectImage3 = async (event) => {
    let file = event.target.files[0];
    if (file.width > 800 || file.height > 600) {
      Seterror("Image dimensions should be less than 800X600 pixels.");
      return;
    }

    // Compress the image
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 800, // Maximum dimensions
      useWebWorker: true, // Enable web worker for faster compression (optional)
    };

    const compressedImage = await imageCompression(file, options);

    try {
      Resizer.imageFileResizer(
        compressedImage,
        800, // New width
        600, // New height
        "JPEG", // Image format
        100, // Image quality (0-100)
        0, // Rotation (optional)
        async (uri) => {
          // 'uri' is the resized image in base64 format
          let photoExt = file.type.split("/")[1];
          setimageext3(photoExt);
          formik.setFieldValue("imageex3", photoExt);
          formik.setFieldValue("base3", uri.split(",")[1]);
          setImage3(uri);
        },
        "base64" // Output format ('base64', 'blob', 'file', or 'dataURL')
      );
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Porject Graphics</p>
            <p className="subTitle">Provide Your Project Logo</p>

            {!spining1 && formik.values.logoext === "" ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    className="logoImage"
                    variant="rectangular"
                    width={150}
                    height={150}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={`data:image/${formik.values.logoext};base64,${formik.values.logobase}`}
                  alt="preview image"
                  className="logoImage"
                  style={{ margin: "15px auto" }}
                />
              </>
            )}

            <h3>150x150</h3>
            <ColorButton variant="contained" component="label">
              Select
              <input hidden accept="image/*" type="file" onChange={Logo} />
            </ColorButton>
            <p className="errorText">{newerror}</p>
            <p className="subTitle">Upload Project Images</p>

            <div className="imagemain">
              <div className="imageContainer">
                {!spining2 && formik.values.imageex1 === "" ? (
                  <>
                    <div>
                      <Skeleton
                        className="logoImage"
                        variant="rectangular"
                        width={100}
                        height={80}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/${formik.values.imageex1};base64,${formik.values.base1}`}
                      width="100px"
                      loading="lazy"
                    />
                  </>
                )}

                <h5>800x600</h5>
                <ColorButton
                  variant="contained"
                  component="label"
                  style={{ marginTop: 20 }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={ProjectImage1}
                  />
                  Upload
                </ColorButton>
              </div>

              <div className="imageContainer">
                {!spining3 && formik.values.imageex2 === "" ? (
                  <>
                    <div>
                      <Skeleton
                        className="logoImage"
                        variant="rectangular"
                        width={100}
                        height={80}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/${formik.values.imageex2};base64,${formik.values.base2}`}
                      width="100px"
                      loading="lazy"
                    />
                  </>
                )}

                <h5>800x600</h5>
                <ColorButton
                  variant="contained"
                  component="label"
                  style={{ marginTop: 20 }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={ProjectImage2}
                  />
                  Upload
                </ColorButton>
              </div>

              <div className="imageContainer">
                {!spining4 && formik.values.imageex3 === "" ? (
                  <>
                    <div>
                      <Skeleton
                        className="logoImage"
                        variant="rectangular"
                        width={100}
                        height={80}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/${formik.values.imageex3};base64,${formik.values.base3}`}
                      width="100px"
                      loading="lazy"
                    />
                  </>
                )}

                <h5>800x600</h5>
                <ColorButton
                  variant="contained"
                  component="label"
                  style={{ marginTop: 20 }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={ProjectImage3}
                  />
                  Upload
                </ColorButton>
              </div>
            </div>
          </div>
        </div>
        <div className="FormContainer">
          <form onSubmit={formik.handleSubmit}>
            <div className="innerGroup">
              <p>Project Video URL</p>
              <TextField
                id="video"
                name="video"
                label="Video"
                margin="normal"
                sx={{ width: 500 }}
                // type={"url"}
                type="url"
                placeholder="Please Provide Your Project Youtube Video URL"
                onChange={formik.handleChange}
                value={formik.values.video}
                error={formik.touched.video && Boolean(formik.errors.video)}
                helperText={formik.touched.video && formik.errors.video}
              />
            </div>
            <ColorButton type="submit">next</ColorButton>
          </form>
        </div>
      </div>
    </div>
  );
};
