import { AccountCircle, Publish } from "@mui/icons-material";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import service from "./service/Service";

const drawerWidth = 240;

export default function Dashboard() {
  let navigate = useNavigate();
  const NavigationDecide = () => {
    service
      .post(`auth/logout`)
      .then((response) => {
        //console.log("Loggedout", response);
        localStorage.removeItem("j");
        localStorage.removeItem("role");
        navigate("/");
      })
      .catch((error) => {
        // console.log(error, "Logout Error");
      });
  };

  const icons = [<AccountCircle />, <Publish />, <SubtitlesIcon />];

  const [profilepicture, Setprofilepicture] = React.useState("");
  // const [getstudents, setstudent] = React.useState("");
  const [ispublish, setisPublish] = React.useState(false);
  const [arr, setarr] = React.useState(["Your Profile", "My Projects"]);
  useEffect(() => {
    service
      .get("student-projects")
      .then((res) => {
        let project = res.data.projects.length;

        if (!project) {
          setarr([...arr, "Publish"]);
        }
      })
      .catch((err) => {
        // console.log(er, "EERRR");
      });
    // axios
    //   .get(`https://itvission.codeonroad.com/show-publish`)
    //   .then((response) => {
    //     console.log(response.data, "student info");
    //     if (response.data == true) {
    //       setarr([...arr, "Publish"]);
    //     } else {
    //       setarr(["Your Profile", "My Projects"]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar
          style={{
            backgroundImage: `linear-gradient(to right, #169b8a, rgba(19, 163, 16, 0.6))`,
          }}
        >
          <Typography variant="h6" noWrap component="div">
            Student Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <img
          src={require("./Images/vision.png")}
          alt="preview image"
          style={{
            width: 110,
            height: 110,
            margin: "10%",
            marginLeft: "25%",
          }}
        />

        <Divider />

        <List>
          {arr.map((text, index) => (
            <ListItem key={text}>
              <Link
                to={`${text.replace(/\s+/g, "").toLowerCase()}`}
                style={{ width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>{icons[index]}</ListItemIcon>

                  <ListItemText primary={text} sx={{ color: "black" }} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <Button
            onClick={() => NavigationDecide()}
            variant="contained"
            style={{
              backgroundColor: "#169b8a",
              width: 200,
              borderRadius: 20,
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            Logout
          </Button>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
