import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import service from "./service/Service";
import EventIcon from "@mui/icons-material/Event";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NoteIcon from "@mui/icons-material/Note";
import CategoryIcon from "@mui/icons-material/Category";
import ComputerIcon from "@mui/icons-material/Computer";
import FactoryIcon from "@mui/icons-material/Factory";
const drawerWidth = 260;

const icons = [
  <EventIcon />,
  <AddCircleOutlineIcon />,
  <AdminPanelSettingsIcon />,
  <ManageAccountsIcon />,
  <PendingActionsIcon />,
  <NoteIcon />,
];

const ProjectType = [<CategoryIcon />, <ComputerIcon />, <FactoryIcon />];
export function SuperAdmin() {
  let navigate = useNavigate();
  const NavigationDecide = () => {
    service
      .post(`auth/logout`)
      .then((response) => {
        // console.log("Loggedout", response);
      })
      .catch((error) => {
        console.log(error, "Logout Error");
      });

    localStorage.removeItem("j");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar
          style={{
            backgroundImage: `linear-gradient(to right, #169b8a, rgba(19, 163, 16, 0.6))`,
          }}
        >
          <Typography variant="h6" noWrap component="div">
            Center Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <img
          src={require("./Images/vision.png")}
          alt="preview image"
          style={{
            width: 110,
            height: 110,
            margin: "10%",
            marginLeft: "25%",
          }}
        />

        <Divider />

        <List>
          {[
            "Manage Event",
            "Create Center",
            "Manage Centers",
            "Manage Students",
            "Pending Projects",
            "Overall Projects",
          ].map((text, index) => (
            <ListItem key={text}>
              <Link
                to={`${text.replace(/\s+/g, "").toLowerCase()}`}
                style={{ width: "100%" }}
              >
                <ListItemButton>
                  <ListItemIcon>{icons[index]}</ListItemIcon>

                  <ListItemText primary={text} sx={{ color: "black" }} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <p>
            <u>Project Types</u>
          </p>

          {["Categories", "Technologies", "Industries"].map((text, index) => (
            <ListItem key={text}>
              <ListItemButton>
                <ListItemIcon>{ProjectType[index]}</ListItemIcon>
                <Link to={`${text.replace(/\s+/g, "").toLowerCase()}`}>
                  <ListItemText primary={text} sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
            </ListItem>
          ))}

          <Button
            onClick={() => NavigationDecide()}
            variant="contained"
            style={{
              backgroundColor: "#169b8a",
              width: 200,
              borderRadius: 20,
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            Logout
          </Button>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
