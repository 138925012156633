import CircleIcon from "@mui/icons-material/Circle";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import service from "../service/Service";
import { LoadingComponent } from "./LoadingComponent";

export const AllCenters = () => {
  const [responsestatus, Setresponsestatus] = React.useState("");
  const [checked, setChecked] = React.useState(true);

  var count = 1;

  // function createData(Centerid, Centername, Centeremail) {
  //   return { Centerid, Centername, Centeremail };
  // }

  // const rows = [createData(`-`, "-", "-")];
  const [getcenters, Setcenters] = React.useState([]);

  const getAllcenters = () => {
    service.get(`all-centers`).then((respons) => {
      let { centers } = respons.data;
      Setcenters(centers);
      // console.log(respons.data);
    });
  };

  React.useEffect(() => {
    getAllcenters();
  }, []);

  const notifyfordelete = () => toast.error("Category Deleted Successfully");

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <p className="formTitle">All Centers </p>
        </div>
      </div>
      {getcenters.length !== 0 ? (
        <div className="cardContainer">
          {getcenters.map((row, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <div className="cardList">
                <div className="splitLeft">
                  <div className="sno">{count++}</div>
                  <div className="cardInfo">
                    <div className="cardId">{row.cname}</div>
                  </div>
                </div>
                <div className="cardActions">
                  {row.status == "active" ? (
                    <p style={{ color: "green" }}>
                      <CircleIcon
                        style={{
                          color: "green",
                          marginBottom: -1,
                          fontSize: 11,
                        }}
                      />
                      ACTIVE
                    </p>
                  ) : (
                    <p style={{ color: "silver" }}>
                      <CircleIcon
                        style={{
                          color: "silver",
                          marginBottom: -1,
                          fontSize: 11,
                        }}
                      />
                      INACTIVE
                    </p>
                  )}
                </div>
              </div>
            </TableRow>
          ))}
        </div>
      ) : (
        <LoadingComponent />
      )}
      <ToastContainer />
    </div>
  );
};
