import axios from "axios";
import { BaseURL } from "../config";

const service = axios.create({
  // baseURL: 'https://www.itvission.codeonroad.com/api/',
  baseURL: BaseURL,
});
const gettoken = async () => {
  const token = localStorage.getItem("j");
  const parsedtoken = token;
  return parsedtoken;
};
service.interceptors.request.use(
  async (config) => {
    let token = await gettoken();
    config.headers.Accept = "application/json";
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
export default service;
