import LoadingButton from "@mui/lab/LoadingButton";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import service from "../service/Service";

const validationSchema = yup.object({
  name: yup.string("Enter Center Name").required("Center Name Required"),
  email: yup
    .string("Enter Center E-Mail")
    .email()
    .required("Center E-Mail is required"),
  password: yup
    .string("Set Center password")
    .min(8, "Password Must be 8 characters Long")
    .required("Center password is required"),
});

export const ADDAdmins = () => {
  const navigate = useNavigate();
  const [responsestatus, Setresponsestatus] = useState(1);
  const [loadingbtn, Setloadinbtn] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      RegisterCenter(values, resetForm);
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  const notify = () => toast.success("Center Created Successfully");
  const Error = (error) => toast.error(`${error}`);

  const RegisterCenter = (values, resetForm) => {
    // console.log(values, "Input");
    Setloadinbtn(true);

    service
      .post(`create-center`, values)
      .then((res) => {
        //console.log(res.data);
        if (res.data.error) {
          Setloadinbtn(false);
          //  console.log(res.data.error);
          Error(res.data.error.email);
        } else {
          // console.log(res.data.status);
          Setresponsestatus(1);
          notify();
          resetForm();
          Setloadinbtn(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <p className="formTitle">Create Center</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="innerGroup">
              <p>Center Name</p>
              <TextField
                id="name"
                name="name"
                label="Set center Name"
                margin="normal"
                className="input"
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="innerGroup">
              <p>Center Email</p>
              <TextField
                id="email"
                name="email"
                label="Set Center Email"
                margin="normal"
                className="input"
                onChange={formik.handleChange("email")}
                value={formik.values.email}
                onBlur={formik.handleBlur("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>

            <div className="innerGroup">
              <p>Set Password </p>
              <TextField
                id="password"
                name="password"
                label="Set Password "
                type={"password"}
                margin="normal"
                className="input"
                onChange={formik.handleChange("password")}
                value={formik.values.password}
                onBlur={formik.handleBlur("password")}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            {/* <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton> */}

            <LoadingButton
              loading={loadingbtn}
              variant="outlined"
              type="submit"
              sx={{ backgroundColor: "#169b8a", color: "white" }}
            >
              Create
            </LoadingButton>
          </form>
        </div>
      </div>
      {/* {responsestatus == 1 ? <ToastContainer /> : <></>} */}
      <ToastContainer />
    </div>
  );
};
