import { Button } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

//Search
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import service from "../../service/Service";

const validationSchema = yup.object({
  category: yup
    .string("Add Project Category")
    .required("Please select a Value"),
  industry: yup
    .string("Add Project Industry")
    .required("Please select a Value"),
  technology: yup
    .string("Add Project Technology")
    .required("Please select a Value"),
  content: yup.string("Add Project Content").required("Please Select a Value"),
});

export const Catogary = ({ handleNext }) => {
  const [age, setage] = React.useState("");
  const [getindustry, Setindustry] = React.useState([]);
  const [getcategory, Setcategory] = React.useState([]);
  const [gettechnology, Settechnology] = React.useState([]);
  const [industryid, setindustryid] = React.useState([]);
  const [technologyid, Settechnologyid] = useState([]);
  const [categoryid, Setcategoryid] = useState([]);

  const myAllData = useSelector((state) => state.Alldata.data);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(myAllData);
    if (myAllData.p_id) {
      // console.log("works");
      // console.log();
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      categoryid: myAllData.categoryid || myAllData.cat_id || "",
      category: myAllData.category || myAllData.cat || "",
      technologyid: myAllData.technologyid || myAllData.tech_id || "",
      technology: myAllData.technology || myAllData.tech || "",
      industryid: myAllData.industryid || myAllData.ind_id || "",
      industry: myAllData.industry || myAllData.ind || "",
      content: myAllData.content || myAllData.age_rating || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "addxyz",
        payload: values,
      });
      dispatch({ type: "next", payload: true });
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  const GetAllIndustry = () => {
    service.get(`all-industries`).then((res) => {
      let { industries } = res.data;
      Setindustry(industries);
    });
  };
  const GetAllTechnology = () => {
    service.get(`all-technologies`).then((res) => {
      let { technologies } = res.data;
      Settechnology(technologies);
    });
  };
  const GetAllCategory = () => {
    service.get(`all-categories`).then((res) => {
      let { categories } = res.data;
      Setcategory(categories);
    });
  };

  useEffect(() => {
    GetAllIndustry();
    GetAllCategory();
    GetAllTechnology();
  }, []);

  const getcontent = [
    { name: "18+" },
    { name: "13+" },
    { name: "3+" },
    { name: "everyone" },
  ];

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Categorization</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Project Category</p>
                <Autocomplete
                  fullWidth
                  options={getcategory.map((e) => e)}
                  value={{ name: formik.values.category }}
                  onChange={(event, value) => {
                    formik.setFieldValue("category", value.name);
                    formik.setFieldValue("categoryid", value.id);
                    Setcategoryid(value.id);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="category"
                      id="category"
                      label="Category"
                      margin="normal"
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                    />
                  )}
                />
              </div>

              <div className="innerGroup">
                <p>Project Technology</p>
                <Autocomplete
                  fullWidth
                  options={gettechnology.map((e) => e)}
                  value={{ name: formik.values.technology }}
                  onChange={(e, value) => {
                    console.log(value);
                    formik.setFieldValue("technology", value.name);
                    formik.setFieldValue("technologyid", value.id);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="technology"
                      id="technology"
                      label="Technology"
                      margin="normal"
                      error={
                        formik.touched.technology &&
                        Boolean(formik.errors.technology)
                      }
                      helperText={
                        formik.touched.technology && formik.errors.technology
                      }
                    />
                  )}
                />
              </div>

              <div className="innerGroup">
                <p>Project Industry</p>
                <Autocomplete
                  fullWidth
                  options={getindustry.map((e) => e)}
                  value={{ name: formik.values.industry }}
                  onChange={(event, value) => {
                    formik.setFieldValue("industry", value.name);
                    formik.setFieldValue("industryid", value.id);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="industry"
                      id="industry"
                      label="Industry"
                      margin="normal"
                      error={
                        formik.touched.industry &&
                        Boolean(formik.errors.industry)
                      }
                      helperText={
                        formik.touched.industry && formik.errors.industry
                      }
                    />
                  )}
                />
              </div>

              <div className="innerGroup">
                <p>Project content</p>
                <Autocomplete
                  fullWidth
                  placeholder="Select Content"
                  options={getcontent.map((e) => e.name)}
                  value={formik.values.content}
                  onChange={(e, v) => {
                    formik.setFieldValue("content", v);
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="content"
                      id="content"
                      label="Content Type"
                      margin="normal"
                      error={
                        formik.touched.content && Boolean(formik.errors.content)
                      }
                      helperText={
                        formik.touched.content && formik.errors.content
                      }
                    />
                  )}
                />
              </div>

              <ColorButton type="submit">next</ColorButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
