import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { ExportToExel } from "../../ExportToExcel";
import service from "../../service/Service";

const validationSchema = yup.object({
  name: yup.string("ADD New Category").required("Category Name Required"),
});

export const Categories = () => {
  const [getcenters, Setcatogaries] = useState([]);
  const [responsestatus, Setresponsestatus] = useState("");
  const [responsedelete, Setresponsedelete] = useState("");
  const [searchTxt, setsearchTxt] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      RegisterCategory(values);
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));
  var count = 1;
  const notify = () => toast.success("Category Created Successfully");
  const notifyerror = (name) => toast.error(`${name}`);
  const notifyfordelete = () => toast.error(`Category Deleted`);

  const RegisterCategory = (values) => {
    // console.log(values, "Input");

    service
      .post(`create-category`, values)
      .then((res) => {
        if (res.data.error) {
          // console.log(res.data.error.name);
          notifyerror(res.data.error.name);
          Setresponsestatus(res.data.status);
        } else {
          Setresponsestatus(res.data.status);

          notify();
          LoadData();
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const deletecatogery = (id) => {
    service
      .get(`delete-category/${id}`)
      .then((res) => {
        // console.log(res);
        Setresponsedelete(res.data.status);
        //console.log(res.data.status);
        notifyfordelete();
        LoadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const LoadData = () => {
    service.get(`all-categories`).then((response) => {
      let { categories } = response.data;
      //  console.log(categories);
      Setcatogaries(categories);
    });
  };
  React.useEffect(() => {
    LoadData();
  }, []);

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <p className="formTitle">Create Category</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="innerGroup">
              <p>Category Name</p>
              <TextField
                id="name"
                name="name"
                label="Category Name"
                margin="normal"
                className="input"
                onChange={formik.handleChange("name")}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <ColorButton type="submit">ADD</ColorButton>
          </form>
        </div>
      </div>
      <div>
        <p
          style={{
            fontSize: 30,
            letterspacing: 2,
            fontWeight: "bold",
            color: "#169b8a",
            opacity: 0.8,
            marginBottom: 10,
          }}
        >
          ALL Categories
        </p>
      </div>

      {getcenters?.length !== 0 ? (
        <div
          className="cardContainer"
          style={{
            padding: "5px",
            width: "80%",
          }}
        >
          <TextField
            id="search"
            name="search"
            label="Search Category"
            margin="normal"
            className="Textinput"
            value={searchTxt}
            onChange={handleChangeSearch}
          />
          <TableContainer>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => ExportToExel(getcenters, "Categories")}
                variant="contained"
                color="info"
                style={{ marginBottom: 10 }}
              >
                Export
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sno</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {getcenters &&
                    getcenters
                      .filter((e) =>
                        e.name.toLowerCase().includes(searchTxt.toLowerCase())
                      )
                      .map((row, i) => (
                        <TableRow>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => deletecatogery(row.id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <CircularProgress />
      )}
      <ToastContainer />
    </div>
  );
};
