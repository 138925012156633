import { Button, CircularProgress, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import service from "../service/Service";

export const Status = () => {
  const [projectsxyz, setprojects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchval, setSearchVal] = useState("");
  const dispatch = useDispatch();
  const [btn, setBtn] = useState(false);
  const [msg, setmsg] = useState(false);
  useEffect(() => {
    // axios
    //   .get(`${BaseURL}student-projects`, { headers: Bearer })
    //   .then((res) => {
    //     setLoading(false);
    //     // console.log(res.data);
    //     const { projects } = res.data;
    //     setprojects(projects);

    //     axios
    //       .get(`${BaseURL}studentInfo`, { headers: Bearer })
    //       .then((response) => {
    //         // console.log(response.data.studentInfo, "student info");
    //         const { user_id } = response.data.studentInfo;
    //         const find = projects.filter((e) => e.created_by_id != user_id);
    //         // console.log(find)
    //         if (find.length == 0) {
    //           setBtn(true);
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.status === 500) {
    //       setLoading(false);
    //       setmsg(true);
    //     }
    //   });

    service
      .get("student-projects")
      .then((res) => {
        setLoading(false);

        const { projects } = res.data;
        setprojects(projects);

        service
          .get("studentInfo")
          .then((response) => {
            const { user_id } = response.data.studentInfo;
            const find = projects.filter((e) => e.created_by_id != user_id);
            if (find.length == 0) {
              setBtn(true);
            }
          })
          .catch((error) => {});
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setLoading(false);
          setmsg(true);
        }
      });
  }, []);

  const navigate = useNavigate();

  const handleserach = (e) => {
    // console.log(e.target.value);
    const val = e.target.value;
    setSearchVal(val);
  };

  return (
    <>
      {!loading ? (
        <>
          {msg ? (
            <>
              <div
                style={{
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "#aaa" }}>
                  No Project Found. Please ask your Group leader to add you in
                  project
                </h2>
              </div>
            </>
          ) : (
            <>
              {projectsxyz.length !== 0 ? (
                <>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      sx={{ width: "90%", margin: "10px" }}
                      type="search"
                      onChange={handleserach}
                      placeholder="Search"
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sno.</TableCell>
                          <TableCell>Center Name</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Technology</TableCell>
                          <TableCell>Publish Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectsxyz
                          .filter(
                            (e) =>
                              e.p_name.toLowerCase().includes(searchval) ||
                              e.center_name.toLowerCase().includes(searchval) ||
                              e.cat.toLowerCase().includes(searchval) ||
                              e.tech.toLowerCase().includes(searchval)
                          )
                          .map((e, i) => (
                            <TableRow key={i}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{e.center_name}</TableCell>
                              <TableCell>{e.p_name}</TableCell>
                              <TableCell>{e.cat}</TableCell>
                              <TableCell>{e.tech}</TableCell>
                              <TableCell>{e.created_at}</TableCell>
                              <TableCell>
                                {e.status === 0
                                  ? "Pending with center"
                                  : e.status === 1
                                  ? "Pending with FSO"
                                  : e.status === 2
                                  ? "Publish"
                                  : e.status === 3
                                  ? "Rejected By FSO"
                                  : e.status === 4
                                  ? "Rejected By Center"
                                  : ""}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/dashboard/projectdetails/${e.p_id}`}
                                >
                                  <Button variant="contained">View</Button>
                                </Link>
                                {btn ? (
                                  <>
                                    {e.status === 4 ? (
                                      <>
                                        <Button
                                          variant="contained"
                                          color="error"
                                          style={{ marginLeft: "5px" }}
                                          onClick={() => {
                                            dispatch({
                                              type: "addxyz",
                                              payload: e,
                                            });
                                            navigate("/dashboard/publish");
                                          }}
                                        >
                                          Update
                                        </Button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2 style={{ color: "#aaa" }}>No Data Found...</h2>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};
