import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Aos from "aos";
import "aos/dist/aos.css";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import service from "../service/Service";
import { Catogary } from "./PublishUI.js/Catogary";
import { FacultyDetails } from "./PublishUI.js/FacultyDetails";
import { ProjectDetails } from "./PublishUI.js/ProjectDetails";
import { Projectgraphics } from "./PublishUI.js/ProjectGraphics";
import { ReleaseDetails } from "./PublishUI.js/ReleaseDetails";
import { TeamDetails } from "./PublishUI.js/TeamDetails";
import { useNavigate } from "react-router-dom";
Aos.init();

const steps = [
  {
    label: "Provide Project Category",
    description: <Catogary />,
  },
  {
    label: "Provide Project Details",
    description: <ProjectDetails />,
  },
  {
    label: "Project Graphics",
    description: <Projectgraphics />,
  },
  {
    label: "Relase Details",
    description: <ReleaseDetails />,
  },
  {
    label: "Faculty Details",
    description: <FacultyDetails />,
  },
  {
    label: "Team Details",
    description: <TeamDetails />,
  },
];

export default function Publish() {
  const navigate = useNavigate();
  // const ProjectCatogary = useSelector((state) => state.ProjectCatogary);
  // const ProjectDetails = useSelector((state) => state.ProjectDetails);
  // const Projectgraphics = useSelector((state) => state.ProjectGraphics);
  // const ReleaseDetails = useSelector((state) => state.ReleaseDetails);
  // const TeamDetails = useSelector((state) => state.TeamDetails);
  // const Faculty = useSelector((state) => state.Faculty);
  const Next = useSelector((state) => state.Next);
  const alldata = useSelector((state) => state.Alldata.data);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    //console.log(alldata, "AllData");
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (Next) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch({ type: "next", payload: false });
    }
  }, [Next]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));
  const Published = () => toast.success(`Your Project Successfully Published`);
  const PublishData = () => {
    setLoading(true);
    // const { RelaseType } = alldata;
    const { TeamMember } = alldata;
    let myCustom = alldata;
    myCustom.TeamMember = [];
    if (TeamMember.length !== 0) {
      for (let i = 0; i < TeamMember.length; i++) {
        const e = TeamMember[i];
        myCustom.TeamMember.push(e.user_id);
      }
    }
    // console.log(myCustom);
    let role = localStorage.getItem("role");
    if (role === "xxst") {
      if (myCustom.p_id) {
        //console.log(myCustom.p_id);
        const payload = {
          industryid: myCustom.industryid,
          categoryid: myCustom.categoryid,
          technologyid: myCustom.technologyid,
          content: myCustom.content,
          Projectname: myCustom.Projectname,
          shortDescription: myCustom.shortDescription,
          LongDescription: myCustom.LongDescription,
          video: myCustom.video,
          logobase: myCustom.logobase,
          logoext: myCustom.logoext,
          RelaseType: myCustom.RelaseType,
          gitHubUrl: myCustom.gitHubUrl,
          TeamName: myCustom.TeamName,
          TeamMember: myCustom.TeamMember,
          access: myCustom.access,
          teacherid: myCustom.teacherid,
          project_history: myCustom.project_history,
          team: myCustom.team,
          TeamMember: myCustom.TeamMember,
        };

        service
          .post(`update-project/${myCustom.p_id}`, payload)
          .then((res) => {
            const imgpay = {
              p_id: myCustom.p_id,
              ext: myCustom.imageex1,
              base: myCustom.base1,
            };

            service
              .post(`upload-project-screenshot`, imgpay)
              .then((e) => {
                const imgpay1 = {
                  p_id: myCustom.p_id,
                  ext: myCustom.imageex2,
                  base: myCustom.base2,
                };

                service
                  .post(`upload-project-screenshot`, imgpay1)
                  .then((e) => {
                    const imgpay2 = {
                      p_id: myCustom.p_id,
                      ext: myCustom.imageex3,
                      base: myCustom.base3,
                    };

                    service
                      .post(`upload-project-screenshot`, imgpay2)
                      .then((e) => {
                        // console.log("UPLOADED");
                        Published();
                        dispatch({ type: "clearobj" });
                        // window.location.reload(true);
                        navigate("/dashboard/myprojects");
                        setLoading(false);
                      })
                      .catch((e) => {
                        // console.log(e);
                      });
                  })
                  .catch((e) => {
                    // console.log(e);
                  });
              })
              .catch((e) => {
                // console.log(e);
              });
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } else {
        // console.log(myCustom);
        service
          .post(`add-project`, myCustom)
          .then((res) => {
            //  console.log(res.data);
            toast.success(`Your Project Successfully Published`);
            navigate("/dashboard/myprojects");
            Published();
            dispatch({ type: "clearobj" });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            // console.log(error);
          });
      }
    } else if (role === "xxca") {
      if (myCustom.p_id) {
        const payload = {
          industryid: myCustom.industryid,
          categoryid: myCustom.categoryid,
          technologyid: myCustom.technologyid,
          content: myCustom.content,
          Projectname: myCustom.Projectname,
          shortDescription: myCustom.shortDescription,
          LongDescription: myCustom.LongDescription,
          video: myCustom.video,
          logobase: myCustom.logobase,
          logoext: myCustom.logoext,
          RelaseType: myCustom.RelaseType,
          gitHubUrl: myCustom.gitHubUrl,
          TeamName: myCustom.TeamName,
          TeamMember: myCustom.TeamMember,
          access: myCustom.access,
          teacherid: myCustom.teacherid,
          project_history: myCustom.project_history,
          team: myCustom.team,
          TeamMember: myCustom.TeamMember,
        };

        service
          .post(`center-update-project/${myCustom.p_id}`, payload)
          .then((res) => {
            const imgpay = {
              p_id: myCustom.p_id,
              ext: myCustom.imageex1,
              base: myCustom.base1,
            };

            service
              .post(`upload-project-screenshot`, imgpay)
              .then((e) => {
                const imgpay1 = {
                  p_id: myCustom.p_id,
                  ext: myCustom.imageex2,
                  base: myCustom.base2,
                };

                service
                  .post(`upload-project-screenshot`, imgpay1)
                  .then((e) => {
                    const imgpay2 = {
                      p_id: myCustom.p_id,
                      ext: myCustom.imageex3,
                      base: myCustom.base3,
                    };

                    service
                      .post(`upload-project-screenshot`, imgpay2)
                      .then((e) => {
                        Published();
                        dispatch({ type: "clearobj" });
                        // window.location.reload(true);
                        navigate("/dashboard/myprojects");
                        setLoading(false);
                      })
                      .catch((e) => {
                        // console.log(e);
                      });
                  })
                  .catch((e) => {
                    // console.log(e);
                  });
              })
              .catch((e) => {
                // console.log(e);
              });
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      }
    }
    // setLoading(false);
    // if (RelaseType === "0") {
    //   myCustom.projectbase = undefined;
    //   myCustom.projectext = undefined;
    //   console.log(myCustom, "myCustom");
    //   axios
    //     .post(`${BaseURL}add-project`, myCustom, { headers: Bearer })
    //     .then((res) => {
    //       console.log(res.data);
    //       Published();
    //       setLoading(false);
    //       dispatch({ type: "clearobj" });
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       console.log(error);
    //     });
    // }
    // else if (RelaseType === "1") {
    //   myCustom.gitHubUrl = undefined;
    //   myCustom.projectbase = undefined;
    //   myCustom.projectext = undefined;
    //   console.log({ myCustom, projectbase, projectext }, "myCustom");
    //   axios
    //     .post(`${BaseURL}add-project`, myCustom, { headers: Bearer })
    //     .then((res) => {
    //       console.log(res.data);
    //       axios
    //         .post(
    //           `${BaseURL}upload-project-file`,
    //           {
    //             p_id: res.data.p_id,
    //             file: projectbase.split(",")[1],
    //             ext: projectext,
    //           },
    //           { headers: Bearer }
    //         )
    //         .then((res) => {
    //           console.log(res);
    //           Published();
    //           setLoading(false);
    //           dispatch({ type: "clearobj" });
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //           console.log(err);
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };
  return (
    <div data-aos="fade-right">
      <Box sx={{ maxWidth: "500" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                // StepIconComponent={ArrowCircleDownIcon}
                optional={
                  index === 5 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {step.description}
                <Box sx={{ mb: 2 }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1, color: "#169b8a" }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button onClick={handleBack} sx={{ color: "#169b8a" }}>
                Back
              </Button>

              <LoadingButton
                onClick={() => PublishData()}
                loading={loading}
                loadingIndicator="Loading…"
                variant="contained"
              >
                Send For Approval
              </LoadingButton>
            </div>
            {/* <Button
              onClick={handleReset}
              sx={{ mt: 1, mr: 1, color: "#169b8a" }}
            >
              Reset
            </Button> */}
          </Paper>
        ) : (
          <></>
        )}
      </Box>
      <ToastContainer />
    </div>
  );
}
