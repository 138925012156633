import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

//Dialog
import {
  Button,
  CircularProgress,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import service from "../service/Service";
import { ExportToExel } from "../ExportToExcel";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ManageTeacher() {
  const navigate = useNavigate();
  const token = localStorage.getItem("j");
  const [responsestatus, Setresponsestatus] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [searchTxt, setsearchTxt] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25];
  const [allteachers, setallteachers] = useState([]);
  const [teachercount, setteachercount] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const handleChange = (id) => {
    service.post(`update-teacher-status/${id}`).then((response) => {
      // console.log(response.status);
      getAllTeachers();
    });
  };
  var count = 1;

  const [getTeachers, SetTeachers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteid, SetDeleteid] = React.useState("");

  const handleClickOpen = (id) => {
    setOpen(true);
    SetDeleteid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllTeachers = () => {
    service.get(`center-teachers`).then((respons) => {
      let { teachers } = respons.data;
      SetTeachers(
        teachers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
      setallteachers(teachers);
      setteachercount(teachers.length);
      // console.log(respons.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllTeachers();
  }, [page, rowsPerPage]);

  const notifyfordelete = () => toast.error("Teacher Deleted Successfully");
  const Error = (name) => toast.error(`${name}`);

  const deleteTeacher = (id) => {
    service
      .get(`delete-teacher/${id}`)
      .then((res) => {
        if (res.data.status == 0) {
          Error(res.data.error.name);
        } else {
          Setresponsestatus(1);
          //console.log(res.data.status);
          notifyfordelete();
          getAllTeachers();
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <p className="formTitle">Manage Teachers</p>
        </div>
        <div
          className="cardContainer"
          style={{
            width: 1000,
            alignContent: "center",
            marginLeft: "47.5px",
          }}
        >
          <div className="card info">
            {/* <span>Total teachers : {getteachers.length}</span> */}
            <div>
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: 20,
                  padding: 20,
                }}
              >
                Total teachers : {teachercount}
              </span>
            </div>
          </div>
          <TextField
            id="search"
            name="search"
            label="Search Center"
            margin="normal"
            className="Textinput"
            value={searchTxt}
            onChange={handleChangeSearch}
          />
          {!loading ? (
            <>
              {getTeachers?.length !== 0 ? (
                <>
                  <TableContainer>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() =>
                          ExportToExel(allteachers, "Center teachers")
                        }
                        variant="contained"
                        color="info"
                        style={{ marginBottom: 10 }}
                      >
                        Export
                      </Button>
                    </div>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SNo</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {searchTxt !== "" ? (
                          <>
                            {allteachers &&
                              allteachers
                                .filter((e) =>
                                  e.t_name
                                    .toLowerCase()
                                    .includes(searchTxt.toLowerCase())
                                )
                                .map((row, i) => (
                                  <TableRow>
                                    <TableCell>{++i}</TableCell>
                                    <TableCell>{row.t_name}</TableCell>
                                    <TableCell>{row.t_email}</TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={
                                          row.status === "active" ? true : false
                                        }
                                        value=""
                                        onChange={(e) => {
                                          handleChange(row.id);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleClickOpen(row.id)}
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </>
                        ) : (
                          <>
                            {getTeachers.map((row, i) => (
                              <TableRow>
                                <TableCell>{++i}</TableCell>
                                <TableCell>{row.t_name}</TableCell>
                                <TableCell>{row.t_email}</TableCell>
                                <TableCell>
                                  <Switch
                                    checked={
                                      row.status === "active" ? true : false
                                    }
                                    value=""
                                    onChange={(e) => {
                                      handleChange(row.id);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleClickOpen(row.id)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={teachercount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  <CircularProgress />
                </>
              )}
            </>
          ) : (
            <div
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Action
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You want to Delete this Teacher?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              style={{ color: "#169b9a" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteTeacher(deleteid)}
              style={{ color: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer />
        {/* {responsestatus == 0 ? <ToastContainer /> : <></>} */}
      </div>
    </div>

    // <div className="grandContainer">
    //   <div className="FormContainer">
    //     <div className="innerContainer">
    //       <p className="formTitle">Manage Teachers</p>
    //     </div>

    //     <div className="cardContainer">
    //       <span
    //         style={{
    //           color: "green",
    //           fontWeight: "bold",
    //           fontSize: 20,
    //           padding: 20,
    //         }}
    //       >
    //         Total Centers : {getTeachers.length}
    //       </span>
    //       {getTeachers.length !== 0 ? (
    //         <div>
    //           {getTeachers.map((row, i) => (
    //             <div
    //               key={i}
    //               sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //             >
    //               <div className="cardList">
    //                 <div className="splitLeft">
    //                   <div className="sno">{count++}</div>
    //                   <div className="cardInfo">
    //                     <div className="cardId">{row.t_name}</div>
    //                     <span>Mail: {row.t_email}</span>
    //                   </div>
    //                 </div>
    //                 <div className="cardActions">
    //                   {/* <EditIcon
    //                     size="small"
    //                     style={{
    //                       color: "#169b9a",
    //                       marginBottom: -10,
    //                       fontSize: 30,
    //                     }}
    //                   >
    //                     <p
    //                       style={{
    //                         color: "#169b9a",
    //                         fontSize: 15,
    //                         fontWeight: "bold",
    //                       }}
    //                     >
    //                       Edit
    //                     </p>
    //                   </EditIcon> */}
    //                   <DeleteIcon
    //                     onClick={() => handleClickOpen(row.id)}
    //                     style={{
    //                       color: "red",
    //                       marginBottom: -10,
    //                       fontSize: 30,
    //                     }}
    //                   >
    //                     <p
    //                       style={{
    //                         color: "red",
    //                         fontSize: 15,
    //                         fontWeight: "bold",
    //                       }}
    //                     >
    //                       delete
    //                     </p>
    //                   </DeleteIcon>
    //                   <Switch
    //                     checked={row.status === "active" ? true : false}
    //                     onChange={() => handleChange(row.id)}
    //                     inputProps={{ "aria-label": "controlled" }}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           ))}
    //         </div>
    //       ) : (
    //         <CircularProgress />
    //       )}
    //     </div>
    //   </div>

    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     PaperComponent={PaperComponent}
    //     aria-labelledby="draggable-dialog-title"
    //   >
    //     <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
    //       Action
    //     </DialogTitle>
    //     <DialogContent>
    //       <DialogContentText>
    //         Are You Sure You want to Delete this Teacher?
    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button autoFocus onClick={handleClose} style={{ color: "#169b9a" }}>
    //         Cancel
    //       </Button>
    //       <Button
    //         onClick={() => deleteTeacher(deleteid)}
    //         style={{ color: "red" }}
    //       >
    //         Delete
    //       </Button>
    //     </DialogActions>
    //   </Dialog>

    //   {/* {responsestatus == 1 ? <ToastContainer /> : <></>} */}
    //   <ToastContainer />
    // </div>
  );
}
