import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { ExportToExel } from "../../ExportToExcel";
import service from "../../service/Service";

const validationSchema = yup.object({
  name: yup.string("ADD New industry").required("industry Name Required"),
});

export const Industry = () => {
  const [getcenters, Setcatogaries] = useState([]);
  const [responsestatus, Setresponsestatus] = useState("");
  const [responsedelete, Setresponsedelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchTxt, setsearchTxt] = useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      RegisterIndustry(values);
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  var count = 1;

  const notify = () => toast.success("Idustry Created Successfully");
  const notifyfordelete = () => toast.error("Industry Deleted Successfully");

  const RegisterIndustry = (values) => {
    // console.log(values, "Input");

    service
      .post(`create-industry`, values)
      .then((res) => {
        //  console.log(res.data);
        Setresponsestatus(res.data.status);
        notify();
        LoadData();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const deleteindustry = (id) => {
    service
      .get(`delete-industry/${id}`)
      .then((res) => {
        //  console.log(res);
        Setresponsedelete(res.data.status);
        // console.log(res.data.status);
        notifyfordelete();
        LoadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const LoadData = () => {
    service.get(`all-industries`).then((response) => {
      let { industries } = response.data;
      // console.log(industries);
      Setcatogaries(industries);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    LoadData();
  }, []);

  return (
    <div className="grandContainer">
      <div>
        <p
          style={{
            fontSize: 40,
            letterspacing: 2,
            fontWeight: "bold",
            color: "#169b8a",
            opacity: 0.8,
            marginBottom: 10,
          }}
        >
          Set New Industry
        </p>
      </div>

      <div className="FormContainer">
        <div className="innerContainer">
          <p className="formTitle">Create Industries</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="innerGroup">
              <p>Industries Name</p>
              <TextField
                id="name"
                name="name"
                label="Industries Name"
                margin="normal"
                className="input"
                onChange={formik.handleChange("name")}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <ColorButton type="submit">ADD</ColorButton>
          </form>
        </div>
      </div>
      <div>
        <p
          style={{
            fontSize: 30,
            letterspacing: 2,
            fontWeight: "bold",
            color: "#169b8a",
            opacity: 0.8,
            marginBottom: 10,
          }}
        >
          ALL Industries
        </p>
      </div>
      {!loading ? (
        <>
          {getcenters?.length !== 0 ? (
            <div
              className="cardContainer"
              style={{
                padding: "5px",
                width: "80%",
              }}
            >
              <TextField
                id="search"
                name="search"
                label="Search Industry"
                margin="normal"
                className="Textinput"
                value={searchTxt}
                onChange={handleChangeSearch}
              />
              <TableContainer>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={() => ExportToExel(getcenters, "Industries")}
                    variant="contained"
                    color="info"
                    style={{ marginBottom: 10 }}
                  >
                    Export
                  </Button>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sno</TableCell>
                      <TableCell>Industry Name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {getcenters &&
                        getcenters
                          .filter((e) =>
                            e.name
                              .toLowerCase()
                              .includes(searchTxt.toLowerCase())
                          )
                          .map((row, i) => (
                            <TableRow>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => deleteindustry(row.id)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <CircularProgress />
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};
