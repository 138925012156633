const initstate = {
  data: {},
};

const Alldata = (state = initstate, action) => {
  switch (action.type) {
    case "addxyz":
      return {
        ...state,
        data: Object.assign(state.data, action.payload),
      };

    case "clearobj":
      return {
        data: {},
      };

    default:
      return state;
  }
};

export default Alldata;
