import LoadingButton from "@mui/lab/LoadingButton";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import service from "../service/Service";
import Papa from "papaparse";

const validationSchema = yup.object({
  std_id: yup.string("Enter Student ID").required("Student ID is required"),
  name: yup.string("Enter Student Name").required("Student Name is required"),
  password: yup
    .string("Set Student password")
    .min(8, "Password Must be 8 characters Long")
    .required("password is required"),
  email: yup
    .string("Set Student Email")
    .email()
    .required("Student email is required"),
});

export const AddStudents = () => {
  const center_id = localStorage.getItem("c_id");
  const token = localStorage.getItem("j");
  const [responsestatus, Setresponsestatus] = useState(1);
  const [loadingbtn, Setloadinbtn] = useState(false);
  const [importbtn, setimportbtn] = useState(false);
  const formik = useFormik({
    initialValues: {
      std_id: "",
      password: "",
      phone_number: "",
      email: "",
      name: "",
      center_id: `${center_id}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      RegisterStudent(values, resetForm);
    },
  });

  const notify = () => toast.success("Student Created Successfully");
  const notifyimport = () => toast.success("Students Imported Successfully");
  const Error = (iderror) => toast.error(`${iderror}`);
  const ServerError = (err) => toast.error(`${err}`);

  const handleFileUpload = (event) => {
    setimportbtn(true);
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Assumes the first row is the header row
        dynamicTyping: true,

        // Automatically converts numeric values
        complete: (results) => {
          const formattedData = results.data.map((student) => ({
            ...student,
            StudentID: String(student.StudentID),
            Phone: String(student.Phone),
          }));

          formattedData.pop();

          const std = {
            students: formattedData,
          };
          

          console.log(std, "jhkhugg");
          service
            .post("import-student", std)
            .then((res) => {
              setimportbtn(false);
              notifyimport();
              console.log(res, "drrgrdg");
            })
            .catch((ress) => {
              setimportbtn(false);
              // console.log(ress, "Erororororor");
            });
        },
        error: (error) => {
          console.error(error.message);
        },
      });
    }
  };

  const RegisterStudent = (values, resetForm) => {
    //console.log(values, "Input");
    Setloadinbtn(true);

    service
      .post(`create-student`, values)
      .then((res) => {
        Setresponsestatus(1);
        // console.log(res, "Then Error");
        if (res.data.status === 200) {
          console.log(res.data.msg);
          Setloadinbtn(false);
          resetForm();
          notify();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          Setresponsestatus(0);
          let { std_id, email } = err.response.data.error;

          Setloadinbtn(false);
          Error(std_id || email);
        } else if (err.response.status === 500) {
          Setresponsestatus(0);
          Error("Internal server error");
          Setloadinbtn(false);
        } else if (err.response.status === 401) {
          Setloadinbtn(false);
        }
        Setloadinbtn(false);
      });
  };

  const Downloadcsv = () => {
    const csvData = [["StudentID", "Name", "Phone", "Email", "Password"]];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "UploadStudentSample.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ColorButton
              variant="contained"
              component="label"
              disabled={importbtn}
            >
              Import Csv
              <input
                hidden
                accept=".csv"
                type="file"
                onChange={handleFileUpload}
              />
            </ColorButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <Button
              onClick={() => Downloadcsv()}
              className="bbb"
              sx={{
                backgroundColor: "#169b8a",
                color: "white",
                width: 200,
                zIndex: 99999,
              }}
            >
              Download Sample
            </Button>
          </div>
          <p className="formTitle">Register Students</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="innerGroup">
              <p>Student Name</p>
              <TextField
                id="StudentName"
                name="name"
                label="Student Name"
                margin="normal"
                className="input"
                onChange={formik.handleChange("name")}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="innerGroup">
              <p>Student ID</p>
              <TextField
                id="StudentID"
                name="std_id"
                label="Student ID"
                // type={"number"}
                placeholder="Provide Student ID"
                margin="normal"
                className="input"
                onChange={formik.handleChange("std_id")}
                value={formik.values.std_id}
                error={formik.touched.std_id && Boolean(formik.errors.std_id)}
                helperText={formik.touched.std_id && formik.errors.std_id}
              />
            </div>
            <div className="innerGroup">
              <p>Student Email </p>
              <TextField
                id="StudentEmail"
                name="email"
                label="Student Email"
                margin="normal"
                className="input"
                onChange={formik.handleChange("email")}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>

            <div className="innerGroup">
              <p>Student Phone </p>
              <TextField
                id="StudentPhone"
                name="phone_number"
                label="Student Phone"
                margin="normal"
                className="input"
                onChange={formik.handleChange("phone_number")}
                value={formik.values.phone_number}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  formik.touched.phone_number && formik.errors.phone_number
                }
              />
            </div>
            <div className="innerGroup">
              <p>Set Password </p>
              <TextField
                id="SetPassword"
                name="password"
                label="Set Password "
                margin="normal"
                className="input"
                onChange={formik.handleChange("password")}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.SetPassword && formik.errors.password
                }
              />
            </div>
            <LoadingButton
              loading={loadingbtn}
              variant="outlined"
              type="submit"
              sx={{ backgroundColor: "#169b8a", color: "white" }}
            >
              Create
            </LoadingButton>
          </form>
        </div>
      </div>
      {/* {responsestatus == 1 ? <ToastContainer /> : <></>} */}
      <ToastContainer />
    </div>
  );
};
