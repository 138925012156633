import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object({
  Projectname: yup
    .string("Enter your Project Name")
    .required("Project Name Required")
    .max(155, "Title should be of maximum 155 characters"),
  shortDescription: yup
    .string("Enter your Short Description")
    .max(100, "Short Description should be of maximum 100 characters")
    .required("short Description is required"),
  LongDescription: yup
    .string("Enter your Short Description")
    .min(244, "Long Description should be of minimum 244 characters")
    .required("Long Description is required"),
});

export const ProjectDetails = ({ handleNext }) => {
  const [enable, Setenablebutton] = useState(false);

  const myAllData = useSelector((state) => state.Alldata.data);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      Projectname: myAllData.Projectname || myAllData.p_name || "",
      shortDescription: myAllData.shortDescription || myAllData.s_desc || "",
      LongDescription: myAllData.LongDescription || myAllData.l_desc || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "addxyz",
        payload: values,
      });
      dispatch({ type: "next", payload: true });
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div>
      <div className="grandContainer">
        <div className="FormContainer">
          <div className="innerContainer">
            <p className="formTitle">Project Details</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Project Name</p>
                <TextField
                  id="Projectname"
                  name="Projectname"
                  label="Project Name"
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange}
                  value={formik.values.Projectname}
                  error={
                    formik.touched.Projectname &&
                    Boolean(formik.errors.Projectname)
                  }
                  helperText={
                    formik.touched.Projectname && formik.errors.Projectname
                  }
                />
              </div>
              <div className="innerGroup">
                <p>short description</p>
                <TextField
                  id="shortDescription"
                  name="shortDescription"
                  label="Short Description"
                  multiline={true}
                  minRows="2"
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange}
                  value={formik.values.shortDescription}
                  error={
                    formik.touched.shortDescription &&
                    Boolean(formik.errors.shortDescription)
                  }
                  helperText={
                    formik.touched.shortDescription &&
                    formik.errors.shortDescription
                  }
                />
              </div>
              <div className="innerGroup">
                <p>long description</p>
                <TextField
                  id="LongDescription"
                  name="LongDescription"
                  label="Long Description"
                  minRows="5"
                  multiline={true}
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange}
                  value={formik.values.LongDescription}
                  error={
                    formik.touched.LongDescription &&
                    Boolean(formik.errors.LongDescription)
                  }
                  helperText={
                    formik.touched.LongDescription &&
                    formik.errors.LongDescription
                  }
                />
              </div>
              <ColorButton type="submit">next</ColorButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
