import {
  Button,
  CircularProgress,
  TablePagination,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { ExportToExel } from "../ExportToExcel";
import service from "../service/Service";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ManageAdmin() {
  const [deleteid, SetDeleteid] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("j");
  const [responsestatus, Setresponsestatus] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [searchTxt, setsearchTxt] = useState("");
  const [loading, setLoading] = useState(true);
  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    SetDeleteid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (id, e) => {
    service.post(`update-center-status/${id}`).then((res) => {
      // console.log(res.data);
      Setcenters(res.data.centers);
    });
  };
  var count = 1;
  const [getcenters, Setcenters] = React.useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25];
  const [centercount, setcentercount] = useState(0);
  const [allcenters, setallcenters] = useState([]);

  const getAllcenters = () => {
    service.get(`all-centers`).then((res) => {
      let { centers } = res.data;
      setcentercount(centers.length);
      Setcenters(
        centers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
      setallcenters(centers);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllcenters();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const notifyfordelete = () => toast.error("Center Deleted Successfully");
  const Error = (name) => toast.error(`${name}`);

  const deletecenter = (deleteid) => {
    service
      .get(`delete-center/${deleteid}`)
      .then((res) => {
        if (res.data.status == 0) {
          Error(res.data.error.name);
        } else {
          handleClose();
          getAllcenters();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="grandContainer">
      <div className="FormContainer" style={{ width: "95%" }}>
        <div className="innerContainer">
          <p className="formTitle" style={{ margin: 10 }}>
            Manage Centers
          </p>

          <div
            className="cardContainer"
            style={{
              width: 1000,
              alignContent: "center",
              marginLeft: "47.5px",
            }}
          >
            <span
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: 20,
                padding: 20,
              }}
            >
              Total Centers : {centercount}
            </span>

            <TextField
              id="search"
              name="search"
              label="Search Center"
              margin="normal"
              className="Textinput"
              value={searchTxt}
              onChange={handleChangeSearch}
            />
            {!loading ? (
              <>
                {getcenters?.length !== 0 ? (
                  <>
                    <TableContainer>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          onClick={() => ExportToExel(getcenters)}
                          variant="contained"
                          color="info"
                          style={{ marginBottom: 10 }}
                        >
                          Export
                        </Button>
                      </div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sno.</TableCell>
                            <TableCell>Center</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <>
                            {searchTxt !== "" ? (
                              <>
                                {allcenters &&
                                  allcenters
                                    .filter((e) =>
                                      e.cname
                                        .toLowerCase()
                                        .includes(searchTxt.toLowerCase())
                                    )
                                    .map((row, i) => (
                                      <TableRow>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{row.cname}</TableCell>
                                        <TableCell>
                                          <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() =>
                                              handleClickOpen(row.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                        <TableCell>
                                          <Switch
                                            checked={
                                              row.status === "active"
                                                ? true
                                                : false
                                            }
                                            value=""
                                            onChange={(e) => {
                                              handleChange(row.id, e);
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                              </>
                            ) : (
                              <>
                                {getcenters.map((row, i) => (
                                  <TableRow>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{row.cname}</TableCell>
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleClickOpen(row.id)}
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={
                                          row.status === "active" ? true : false
                                        }
                                        value=""
                                        onChange={(e) => {
                                          handleChange(row.id, e);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            )}
                          </>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component="div"
                      count={centercount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are You Sure You want to Delete this Center?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} style={{ color: "#169b9a" }}>
            Cancel
          </Button>
          <Button
            onClick={() => deletecenter(deleteid)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
      {/* {responsestatus == 0 ? <ToastContainer /> : <></>} */}
    </div>
  );
}
