import React from "react";

export const WaitingForPublish = () => {
  return (
    <div>
      <div>
        <p
          style={{
            fontSize: 40,
            letterspacing: 2,
            fontWeight: "bold",
            color: "#169b8a",
            opacity: 0.8,
            marginBottom: 10,
          }}
        >
          Waiting For Publish
        </p>
      </div>
    </div>
  );
};
