import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, TextField } from "@mui/material";
import * as React from "react";

import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { PublicURLImages } from "../config";
import service from "../service/Service";

const validationSchema = yup.object({
  linkstd_linkedin_username: yup
    .string("Linkedin Url Required")
    .url("Linked in Must Be URL")
    .required("Linked URL Required"),

  email: yup.string().email("Inavlid Email").required("Email is required"),
});

export const StudentProfile = () => {
  const [responsestatus, Setresponsestatus] = React.useState(1);
  const [loadingbtn, Setloadinbtn] = React.useState(false);
  const [newerror, Seterror] = React.useState("");
  const [profilepicture, Setprofilepicture] = React.useState("");
  const [getstuddents, setstudents] = React.useState([]);
  const [username, setusername] = React.useState("");
  const [imagebase, setimagebase] = React.useState("");
  const [imageext, Setimageext] = React.useState("");
  const [isImageChange, setIsImageChange] = React.useState(false);

  const onImageChange = async (event) => {
    setIsImageChange(true);
    let base64 = await convertBase64(event.target.files[0]);
    base64 = base64.split(",")[1];
    setimagebase(base64);
    let photoExt = event.target.files[0].type.split("/")[1];
    Setimageext(photoExt);
    console.log("CHANGE");
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formik = useFormik({
    initialValues: {
      linkstd_linkedin_username: getstuddents?.std_linkedin_username || "",
      name: getstuddents?.std_name || "",
      std_id: getstuddents?.std_id || "",
      email: getstuddents?.std_email || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      let obj = Object.assign(
        {
          std_linkedin_username: values.linkstd_linkedin_username,
          email: values.email,
        },
        {
          ext: imageext,
          image: imagebase,
        }
      );

      console.log(obj);

      PostData(obj, resetForm);

      // if (imagebase == "") {
      //   PostData(
      //     {
      //       std_linkedin_username: values.linkstd_linkedin_username,
      //       email: values.email,
      //     },
      //     resetForm
      //   );
      // } else {
      //   PostData(obj, resetForm);
      // }
    },
  });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));
  React.useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    // axios
    //   .get(`${BaseURL}studentInfo`, { headers: Bearer })
    //   .then((response) => {
    //     // console.log(response, "student info");
    //     setstudents(response.data.studentInfo);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    service
      .get("studentInfo")
      .then((response) => {
        setstudents(response.data.studentInfo);
        console.log(response.data.studentInfo);
      })
      .catch((error) => {});
  };

  const PostData = (latestvalues, resetForm) => {
    Setloadinbtn(true);
    service
      .post(`update-student-profile`, latestvalues)
      .then((responnse) => {
        if (responnse.data.status === 200) {
          Setloadinbtn(false);
          toast.success("Profile Updated Successfully");
          getdata();
        }
        Setloadinbtn(false);
      })
      .catch((error) => {
        Setloadinbtn(false);
      });
  };

  return (
    <div>
      <div className="maincontainer">
        <div>
          {!getstuddents.std_name == "" ? (
            <p
              style={{
                fontSize: 40,
                letterspacing: 2,
                fontWeight: "bold",
                color: "#169b8a",
                opacity: 0.8,
                paddingRight: "70%",
                marginBottom: 10,
              }}
            >
              {getstuddents.std_name}'s Profile
            </p>
          ) : (
            <p
              style={{
                fontSize: 40,
                letterspacing: 2,
                fontWeight: "bold",
                color: "#169b8a",
                opacity: 0.8,
                paddingRight: "70%",
                marginBottom: 10,
              }}
            >
              Your Profile
            </p>
          )}
        </div>
        <div className="grandContainer">
          <div>
            {!getstuddents.std_profile_image == "" ? (
              <>
                {isImageChange ? (
                  <>
                    <img
                      src={`data:image/jpeg;base64,${imagebase}`}
                      alt="preview image"
                      style={{
                        width: 200,
                        height: 200,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        borderRadius: 100,
                      }}
                    />
                    <div className="innerGroup">
                      <p>Set Your Profile Picture</p>
                      <ColorButton
                        variant="contained"
                        component="label"
                        fullWidth
                      >
                        Select
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={onImageChange}
                        />
                      </ColorButton>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`${
                        PublicURLImages +
                        "users/" +
                        getstuddents.std_profile_image
                      }`}
                      alt="preview image"
                      style={{
                        width: 200,
                        height: 200,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        borderRadius: 100,
                      }}
                    />
                    <div className="innerGroup">
                      <p>Set Your Profile Picture</p>
                      <ColorButton
                        variant="contained"
                        component="label"
                        fullWidth
                      >
                        Select
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={onImageChange}
                        />
                      </ColorButton>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {imagebase == "" ? (
                  <>
                    <AccountCircleIcon
                      onClick={() => {
                        // console.log("Profile");
                      }}
                      sx={{
                        color: "silver",
                        width: 200,
                        height: 200,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 100,
                        alignSelf: "center",
                      }}
                    />
                    <div className="innerGroup">
                      <p>Set Your Profile Picture</p>
                      <ColorButton
                        variant="contained"
                        component="label"
                        fullWidth
                      >
                        Select
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={onImageChange}
                        />
                      </ColorButton>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/jpeg;base64,${imagebase}`}
                      alt="preview image"
                      style={{
                        width: 200,
                        height: 200,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        borderRadius: 100,
                      }}
                    />
                    <div className="innerGroup">
                      <p>Set Your Profile Picture</p>
                      <ColorButton
                        variant="contained"
                        component="label"
                        fullWidth
                      >
                        Select
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={onImageChange}
                        />
                      </ColorButton>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className="FormContainer">
            <div className="innerContainer">
              <form>
                <div className="innerGroup">
                  <p>Student Name</p>
                  <TextField
                    id="StudentName"
                    placeholder="Loading..."
                    name="name"
                    disabled={true}
                    onChange={formik.handleChange("name")}
                    value={formik.values?.name || ""}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    margin="normal"
                    className="input"
                  />
                </div>
                <div className="innerGroup">
                  <p>Student ID</p>
                  <TextField
                    id="StudentID"
                    name="std_id"
                    placeholder="Loading..."
                    onChange={formik.handleChange("std_id")}
                    value={formik.values?.std_id}
                    error={
                      formik.touched.std_id && Boolean(formik.errors.std_id)
                    }
                    helperText={formik.touched.std_id && formik.errors.std_id}
                    disabled={true}
                    margin="normal"
                    className="input"
                  />
                </div>
                <div className="innerGroup">
                  <p>Student Email </p>
                  <TextField
                    id="StudentEmail"
                    name="email"
                    disabled={false}
                    onChange={formik.handleChange("email")}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    className="input"
                  />
                </div>
                <div className="innerGroup">
                  <p>Set Linkedin </p>
                  <TextField
                    id="SetPassword"
                    name="linkstd_linkedin_username"
                    type={"url"}
                    placeholder={"Provide Your Linkedin Url "}
                    label="Linkedin"
                    margin="normal"
                    className="input"
                    onChange={formik.handleChange("linkstd_linkedin_username")}
                    value={formik.values?.linkstd_linkedin_username || ""}
                    error={
                      formik.touched.linkstd_linkedin_username &&
                      Boolean(formik.errors.linkstd_linkedin_username)
                    }
                    helperText={
                      formik.touched.linkstd_linkedin_username &&
                      formik.errors.linkstd_linkedin_username
                    }
                  />
                </div>
                <p className="errorText">{newerror}</p>

                <LoadingButton
                  style={{ marginLeft: "20%" }}
                  loading={loadingbtn}
                  variant="outlined"
                  onClick={() => formik.handleSubmit()}
                  sx={{ backgroundColor: "#169b8a", color: "white" }}
                >
                  Update
                </LoadingButton>
              </form>
            </div>
          </div>
          {/* {responsestatus == 1 ? <ToastContainer /> : <></>} */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
