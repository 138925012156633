import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Draggable from "react-draggable";
import { ExportToExel } from "../ExportToExcel";
import service from "../service/Service";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ManageStudents = () => {
  const token = localStorage.getItem("j");
  // const [bcolor, Setbcolor] = React.useState("rgba(255, 255, 255, 0.4)");
  const [getstudents, Setstudents] = useState([]);

  const [open, setOpen] = useState(false);
  const [deleteid, SetDeleteid] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchTxt, setsearchTxt] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25];
  const [allstudents, setallstudents] = useState([]);
  const [stdcount, setstdcount] = useState(0);
  const handleChangeSearch = (event) => {
    setsearchTxt(event.target.value);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    SetDeleteid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notifyfordelete = () => toast.error(`Student has been Deleted`);
  const Error = (name) => toast.error(`${name}`);
  const [responsestatus, Setresponsestatus] = React.useState("");

  const handleChange = (id) => {
    service.post(`update-student-status/${id}`).then((response) => {
      GetAllStudents();
    });
  };
  var count = 1;

  const GetAllStudents = () => {
    service.get(`center-students`).then((res) => {
      let { students } = res.data;
      Setstudents(
        students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
      setallstudents(students);
      setstdcount(students.length);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    GetAllStudents();
  }, [page, rowsPerPage]);

  const deletestudent = (id) => {
    service
      .get(`delete-student/${id}`)
      .then((res) => {
        if (res.data.status == 0) {
          Error(res.data.error.name);
        } else {
          Setresponsestatus(1);
          // console.log(res);
          //console.log(res.data.status);
          GetAllStudents();
          notifyfordelete();
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="grandContainer">
      <div className="FormContainer" style={{ width: "95%" }}>
        <div className="innerContainer">
          <p className="formTitle" style={{ margin: 10 }}>
            Manage Students
          </p>
        </div>
        <div
          className="cardContainer"
          style={{
            width: 1000,
            alignContent: "center",
            marginLeft: "47.5px",
          }}
        >
          <div className="card info">
            {/* <span>Total Students : {getstudents.length}</span> */}
            <div>
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: 20,
                  padding: 20,
                }}
              >
                Total Students : {stdcount}
              </span>
            </div>
          </div>
          <TextField
            id="search"
            name="search"
            label="Search Students"
            margin="normal"
            className="Textinput"
            value={searchTxt}
            onChange={handleChangeSearch}
          />
          {stdcount !== 0 ? (
            <>
              {!loading ? (
                <>
                  {getstudents?.length !== 0 ? (
                    <>
                      <TableContainer>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <Button
                            onClick={() =>
                              ExportToExel(getstudents, "Center Students")
                            }
                            variant="contained"
                            color="info"
                            style={{ marginBottom: 10 }}
                          >
                            Export
                          </Button>
                        </div>

                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sno</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Student Id</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {searchTxt !== "" ? (
                              <>
                                {allstudents &&
                                  allstudents
                                    .filter(
                                      (e) =>
                                        e.std_name
                                          .toLowerCase()
                                          .includes(searchTxt.toLowerCase()) ||
                                        e.std_id
                                          .toLowerCase()
                                          .includes(searchTxt.toLowerCase())
                                    )
                                    .map((row, i) => (
                                      <TableRow>
                                        <TableCell>{++i}</TableCell>
                                        <TableCell>{row.std_name}</TableCell>
                                        <TableCell>{row.std_id}</TableCell>
                                        <TableCell>
                                          <Switch
                                            checked={
                                              row.status === "active"
                                                ? true
                                                : false
                                            }
                                            value=""
                                            onChange={(e) => {
                                              handleChange(row.id, e);
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() =>
                                              handleClickOpen(row.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                              </>
                            ) : (
                              <>
                                {getstudents.map((row, i) => (
                                  <TableRow>
                                    <TableCell>{++i}</TableCell>
                                    <TableCell>{row.std_name}</TableCell>
                                    <TableCell>{row.std_id}</TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={
                                          row.status === "active" ? true : false
                                        }
                                        value=""
                                        onChange={(e) => {
                                          handleChange(row.id, e);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleClickOpen(row.id)}
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={stdcount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                height: "10vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#aaa" }}>No Data Found...</h2>
            </div>
          )}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Action
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You want to Delete this Student?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              style={{ color: "#169b9a" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deletestudent(deleteid)}
              style={{ color: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer />
        {/* {responsestatus == 0 ? <ToastContainer /> : <></>} */}
      </div>
    </div>
  );
};
