import { Navigation } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Middlwware = () => {
  const navigate = useNavigate();
  navigate("dashboard");
  const { email, name } = useParams();
  useEffect(() => {
    // console.log(email);
    // console.log(name);
  }, []);

  return <div>Getting Your Data..........</div>;
};

export default Middlwware;
