import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import service from "./service/Service";

const validationSchema = yup.object({
  email: yup.string("Enter Your E-Mail").email().required("E-Mail is required"),
  password: yup
    .string("Enter Your password")
    .min(4, "Password Must be 4 characters Long")
    .required("password is required"),
});

export default function Login() {
  const Error = (message) => toast.error(`${message}`);
  const [loadingbtn, Setloadinbtn] = useState(false);
  const [responsestatus, Setresponsestatus] = useState("");
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Setloadinbtn(true);
      service
        .post(`auth/login`, values)
        .then((res) => {
          // console.log("response ffff", res);
          if (res.data.message == "Login Success") {
            let token = res.data.jwt;
            localStorage.setItem("j", token);
            service.get(`auth/user`).then((response) => {
              // console.log(response.data);
              let { role } = response.data;
              let { id } = response.data;
              // console.log(role);
              localStorage.setItem("role", role);
              Setloadinbtn(false);
              window.location.reload(true);
            });
          } else {
            Setloadinbtn(false);
            // console.log("Error");
          }
        })
        .catch((err) => {
          Setresponsestatus("0");
          // console.log(err, "ERRORR");
          Setloadinbtn(false);
          let { message } = err.response.data;
          Error(message);
        });
    },
  });

  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    color: "white",
    width: 200,
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  // const NavigationDecide = (Values) => {
  //  // navigate("/");
  //   Setloadinbtn(true);
  //   axios
  //     .post(`${BaseURL}auth/login`, Values)
  //     .then((res) => {
  //       if (res.data.message == "Login Success") {
  //         let token = res.data.jwt;
  //         // Setrole(token);
  //         localStorage.setItem("j", token);

  //         axios
  //           .get(`${BaseURL}auth/user`, {
  //             headers: {
  //               Authorization: "Bearer " + token,
  //               Accept: "application/json",
  //             },
  //           })
  //           .then((response) => {
  //             console.log(response.data);
  //             let { role } = response.data;
  //             let { id } = response.data;
  //             console.log(role);
  //             // islogin(true);

  //             localStorage.setItem("role", role);
  //             Setloadinbtn(false);
  //             window.location.reload(true);
  //             // if (role == "xxca") {
  //             //   navigate("Admin");
  //             //   localStorage.setItem("c_id", id);
  //             // } else if (role == "xxsa") {
  //             //   navigate("SuperAdmin");
  //             // } else if (role == "xxst") {
  //             //   navigate("dashboard");
  //             // } else {
  //             //   console.log("error");
  //             // }
  //           });
  //       } else {
  //         Setloadinbtn(false);
  //         console.log("Error");
  //       }
  //     })
  //     .catch((err) => {
  //       Setresponsestatus("0");
  //       console.log(err.response);
  //       Setloadinbtn(false);
  //       let { message } = err.response.data;
  //       Error(message);
  //     });
  // };

  return (
    <div className="mainContainer">
      <div className="backGround"></div>
      <div className="innerContainer">
        <Box
          sx={{
            "& > :not(style)": {
              width: 500,
              height: 550,
              borderRadius: 5,
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <div className="formContainer">
            <img
              src={require("./Images/vision.png")}
              alt="preview image"
              style={{ width: 100, height: 100 }}
            />
            <p className="logo">Login</p>
            <form>
              <TextField
                label="Email"
                placeholder="Enter Your Email"
                variant="outlined"
                style={{ width: "80%", marginBottom: 10 }}
                name="email"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                label="Enter Your Password"
                variant="outlined"
                type={"password"}
                name="password"
                style={{ width: "80%", marginBottom: 10 }}
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <div>
                <Button
                  disabled={loadingbtn}
                  onClick={() => formik.handleSubmit()}
                  className="bbb"
                  sx={{
                    backgroundColor: "#169b8a",
                    color: "white",
                    width: 200,
                    zIndex: 99999,
                  }}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </div>
      <ToastContainer />
    </div>
  );
}
