import { LoadingButton } from "@mui/lab";
import { Button, TextField, styled } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import service from "../service/Service";
import Papa from "papaparse";
Aos.init();

const validationSchema = yup.object({
  t_name: yup.string("Enter Teacher Name").required("Teacher Name is required"),
  t_email: yup
    .string("Set Teacher Email")
    .email()
    .required("Teacher email is required"),
});

export const AddTeacher = () => {
  const token = localStorage.getItem("j");
  const centerid = localStorage.getItem("c_id");
  const [responsestatus, Setresponsestatus] = useState(1);
  const [loadingbtn, Setloadinbtn] = useState(false);
  const [importbtn, setimportbtn] = useState(false);
  const formik = useFormik({
    initialValues: {
      t_name: "",
      t_email: "",
      t_linkedin_username: "",
      center_id: `${centerid}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      RegisterTeacher(values, resetForm);
    },
  });

  const notify = () => toast.success("Teacher Created Successfully");
  const notifyimport = () => toast.success("Teachers Imported Successfully");
  const Error = (error) => toast.error(`${error}`);
  const ServerError = (err) => toast.error(`${err}`);

  const handleFileUpload = (event) => {
    setimportbtn(true);
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Assumes the first row is the header row
        dynamicTyping: true, // Automatically converts numeric values
        complete: (results) => {

          let t = results.data;
          t.pop();
          const teachers = {
            teachers: t,
          };
         
      
          service
            .post("import-teachers", teachers)
            .then((res) => {
              setimportbtn(false);
              notifyimport();
            })
            .catch((ress) => {
              setimportbtn(false);
              // console.log(ress, "Erororororor");
            });
        },
        error: (error) => {
          console.error(error.message);
        },
      });
    }
  };

  const RegisterTeacher = (values, resetForm) => {
    // console.log(values, "Input");
    Setloadinbtn(true);

    service
      .post(`create-teacher`, values)
      .then((res) => {
        Setresponsestatus(1);
        //  console.log(res);
        if (res.data.status == "200") {
          Setloadinbtn(false);
          resetForm();
          notify();
        }
      })
      .catch((err) => {
        console.log(err, "Catch Error");
        if (err.response.status === 400) {
          Setresponsestatus(0);
          let error = "";
          // var deleteed = err.response.data.error;
          // Error(email || deleteed);

          if (err.response.data.error.hasOwnProperty("t_email")) {
            error = err.response.data.error.t_email;
            Error(error);
          } else {
            error = err.response.data.error;
            Error(error);
          }
          Setloadinbtn(false);
          Setresponsestatus(0);
        } else if (err.response.status === 500) {
          Setresponsestatus(0);
          Error("Internal server error");
          Setloadinbtn(false);
        } else if (err.response.status === 401) {
          Setloadinbtn(false);
        }
        Setloadinbtn(false);
      });
  };

  const Downloadcsv = () => {
    const csvData = [["Name", "Email", "LinkedIn"]];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "UploadTeacherSample.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#169b8a",
    "&:hover": {
      backgroundColor: "#169b9a",
    },
  }));

  return (
    <div className="grandContainer">
      <div className="FormContainer">
        <div className="innerContainer">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ColorButton
              variant="contained"
              component="label"
              disabled={importbtn}
            >
              Import Csv
              <input
                hidden
                accept=".csv"
                type="file"
                onChange={handleFileUpload}
              />
            </ColorButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <Button
              onClick={() => Downloadcsv()}
              className="bbb"
              sx={{
                backgroundColor: "#169b8a",
                color: "white",
                width: 200,
                zIndex: 99999,
              }}
            >
              Download Sample
            </Button>
          </div>
          <p className="formTitle">Register Teachers</p>
          <div data-aos="fade-up">
            <form onSubmit={formik.handleSubmit}>
              <div className="innerGroup">
                <p>Teacher Name</p>
                <TextField
                  id="TeacherName"
                  name="t_name"
                  label="Teacher Name"
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange("t_name")}
                  value={formik.values.t_name}
                  error={formik.touched.t_name && Boolean(formik.errors.t_name)}
                  helperText={formik.touched.t_name && formik.errors.t_name}
                />
              </div>

              <div className="innerGroup">
                <p>Teacher Email </p>
                <TextField
                  id="TeacherEmail"
                  name="t_email"
                  label="Teacher Email"
                  margin="normal"
                  className="input"
                  onChange={formik.handleChange("t_email")}
                  value={formik.values.t_email}
                  error={
                    formik.touched.t_email && Boolean(formik.errors.t_email)
                  }
                  helperText={formik.touched.t_email && formik.errors.t_email}
                />
              </div>

              <div className="innerGroup">
                <p>Teacher Linkedin </p>
                <TextField
                  id="TeacherLinkedin"
                  name="t_linkedin_username"
                  label="Teacher Linkedin"
                  margin="normal"
                  className="input"
                  type={"url"}
                  onChange={formik.handleChange("t_linkedin_username")}
                  value={formik.values.t_linkedin_username}
                  error={
                    formik.touched.t_linkedin_username &&
                    Boolean(formik.errors.t_linkedin_username)
                  }
                  helperText={
                    formik.touched.t_linkedin_username &&
                    formik.errors.t_linkedin_username
                  }
                />
              </div>

              <LoadingButton
                loading={loadingbtn}
                variant="outlined"
                type="submit"
                sx={{ backgroundColor: "#169b8a", color: "white" }}
              >
                Create
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      {/* {responsestatus == 0 ? <ToastContainer /> : <></>} */}
    </div>
  );
};
